import React, { useState } from 'react'
import { IonIcon } from '@ionic/react';
import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';
import AdminActionHeader from '../AdminActionHeader/AdminActionHeader';
import AdminAcitonsSelectGroup from '../AdminAcitonsSelectGroup/AdminAcitonsSelectGroup';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";
import { Button } from '../../../../@/components/ui/button';

import { Input } from "../../../../@/components/ui/input";

interface AdminEmailActionTemplateProps {
    closeAction: () => void;
    setActionData: (actionData: emailDataProps) => void;
    className?: string;
}

interface emailDataProps {
    type?: 'email';
    from: string;
    title: string;
    email: string;
    visibleTo: string | string[];
}

const AdminEmailActionTemplate: React.FC<AdminEmailActionTemplateProps> = ({ closeAction, className, setActionData }) => {
    const initialEmail: emailDataProps = {
        type: 'email',
        from: 'info',
        title: '',
        email: '',
        visibleTo: 'all',
    };

    const [emailData, setEmailData] = useState<emailDataProps>(initialEmail);

    const setGroupVisibility = (groupVisibilityId: string) => {
        setEmailData({ ...emailData, visibleTo: groupVisibilityId });
        setActionData(emailData);
    }

    const setEmailFrom = (emailFrom: string) => {
        setEmailData({ ...emailData, from: emailFrom });
        setActionData(emailData);
    }

    const setEmailTitle = (emailTitle: string) => {
        setEmailData({ ...emailData, title: emailTitle });
        setActionData(emailData);
    }

    const handleEmailContent = (emailContent: string) => {
        setEmailData({ ...emailData, email: emailContent });
        setActionData(emailData);
    }

    return (
        <div className={`${className} border border-gray-400 rounded-md`}>
            <AdminActionHeader className="rounded-t-md" title='Send an Email' icon={<IonIcon icon='mail' />} closeAction={() => closeAction()} />
            <div className='py-2 px-4 flex items-center gap-2 border-b border-gray-300'>
                <span>From</span>
                <Select onValueChange={(option) => setEmailFrom(option)}>
                    <SelectTrigger className="w-[180px] outline-none h-8">
                        <SelectValue placeholder="Select Email" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem key='1' value='info@taxi.gr' >
                                info@taxi.gr
                            </SelectItem>
                            <SelectItem key='2' value='driver@taxi.gr' >
                                driver@taxi.gr
                            </SelectItem>
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <div className='py-2 px-4 flex items-center gap-2 '>
                <span >
                    Title
                </span>
                <Input
                    className='h-8 focus-visible:ring-none outline-none ring-offset-0'
                    placeholder='Email Title...'
                    value={emailData.title}
                    onChange={(event) => setEmailTitle(event.target.value)}
                />
            </div>
            <CKEditorComponent className="" onChange={(value) => handleEmailContent(value)} />
            <AdminAcitonsSelectGroup label='Select Visibility Group' setGroup={setGroupVisibility} />

        </div>
    )
}


export default AdminEmailActionTemplate;