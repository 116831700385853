import React from 'react';
import PlayStoreButton from '../../Buttons/PlaystoreButton/PlaystoreButton';
import AppStoreButton from '../../Buttons/AppStoreButton/AppStoreButton';
import './PressHeader.css';

const PressHeader: React.FC = () => {

    return (

        <div className="press_header-container ">
            <div className="home_header-text animate-fadeUp">
                <div className='home_header-text--title animate-fadeUp'>Τύπος</div>
                <div className='home_header-text--text delay-75 animate-fadeUp'>
                Ότι δημοσιεύεται βρίσκεται εδώ. Για ερωτήσεις Τύπου, επίσημες ανακοινώσεις ή άλλα θέματα που
                σχετίζονται με τον Τύπο, επικοινωνήστε μαζί μας στο <a href='mailto:press@taxi.gr'>press@taxi.gr</a>
                </div>
                {/* <div className='button_div delay-150 animate-fadeUp'>
                    <AppStoreButton />
                    <PlayStoreButton />
                </div> */}

            </div>
        </div>

    )

}

export default PressHeader;