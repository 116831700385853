import React, { useState, useEffect } from "react";
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants';
import AdminActionHeader from "../AdminActionHeader/AdminActionHeader";
import { IonIcon } from "@ionic/react";
import AdminAcitonsVisibilitySelect from '../AdminAcitonsSelectGroup/AdminAcitonsSelectGroup';
import TagItem from '../../AdminSettings/AdminSettingsTagManagement/TagItem/TagItem';

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";

type TagType = {
    id: string;
    name: string;
}

interface tagsDataProps {
    type?: 'tags';
    activeTags: TagType[],
    visibilityGroup: string,
}

interface AdminActionsAddTagsProps {
    closeAction: () => void;
    setActionData: (actionData: tagsDataProps) => void;
    className?: string;
}

const AdminActionsAddTags: React.FC<AdminActionsAddTagsProps> = ({ closeAction, className, setActionData }) => {
    const { authTokens } = useAuthContext();
    const [tags, setTags] = useState<TagType[]>([]);
    const [selectedTag, setSelectedTag] = useState<string | undefined>(undefined);
    const [addTagSelect, setAddTagSelect] = useState<boolean>(false);

    const initialTagData: tagsDataProps = {
        type: 'tags',
        activeTags: [],
        visibilityGroup: '',
    };
    const [tagData, setTagData] = useState<tagsDataProps>(initialTagData);

    const toggleAddTagSelect = () => {
        setAddTagSelect(!addTagSelect);
    }

    const setVisibilityGroup = (groupVisibilityId: string) => {
        setTagData({ ...tagData, visibilityGroup: groupVisibilityId })
        setActionData(tagData);
    }

    const resetSelectedTag = () => {
        setSelectedTag(undefined);
    }

    const addActiveTag = (tagId: string) => {
        setTagData({ ...tagData, activeTags: [...tagData.activeTags, { id: tagId, name: tags.find((tag) => tag.id === tagId)?.name || '' }] })
        resetSelectedTag();
        toggleAddTagSelect();
        setActionData(tagData);
    }

    const removeActiveTag = (tagId: string) => {
        setTagData({ ...tagData, activeTags: tagData.activeTags.filter((tag) => tag.id !== tagId) });
        setAddTagSelect(false);
    }

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_tags/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setTags(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    return (
        <div className={`${className} border-gray-400 border rounded-md`}>
            <AdminActionHeader className="rounded-t-md" title='Add Tags' icon={<IonIcon icon='pricetags' />} closeAction={() => closeAction()} />
            <div className="m-2 p-2 flex flex-wrap gap-1">
                {tagData.activeTags.map((tag: any) => (
                    <TagItem key={tag.id} tag={tag.name} tagId={tag.id} onDelete={() => removeActiveTag(tag.id)} />
                ))}
                <div className="relative">
                    <div className="border border-gray-300 h-8 w-8 justify-center flex items-center rounded-sm cursor-pointer hover:bg-gray-100" onClick={toggleAddTagSelect}>
                    {!addTagSelect && (<IonIcon icon="add-outline" />)}
                    {addTagSelect && (<IonIcon icon="remove" />)}
                    </div>
                    {addTagSelect && (
                        <div className="absolute top-0 left-10">
                            <Select onValueChange={(option) => addActiveTag(option)} value={selectedTag}>
                                <SelectTrigger className="w-[180px] outline-none h-8">
                                    <SelectValue placeholder="Select User Group" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {tags
                                            .filter((tag) => !tagData.activeTags.some((activeTag) => activeTag.id === tag.id))
                                            .map((tag: any) => (
                                            <SelectItem key={`tag-${tag.id}-name${tag.name}`} value={tag.id} >
                                                {tag.name}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                    )}
                </div>
            </div>
            <AdminAcitonsVisibilitySelect label='Select Visibility Group' setGroup={setVisibilityGroup} />
        </div>
    )
}

export default AdminActionsAddTags;