import React from "react";
import { Link } from "react-router-dom"
import DefaultLanguageInterface from "../../../interfaces";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import DownloadOurApp from "../../../Components/Home/DownloadOurApp/DownloadOurApp";

const NotFound: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    return (

        <div className="max-w-1128 m-auto pt-12">
            <div className="h-[60vh] flex flex-col items-center justify-center">
                <h1 className="text-6xl text-main font-semibold mb-14">{languageData[404].title}</h1>
                <p className="text-lg mb-5">{languageData[404].subtitle}</p>
                <Link to={`${activeLanguage == 'en' ? '/en' : ''}/passenger`} className="text-white btn btn-primary bg-main py-[0.565rem] px-6 rounded-full hover:bg-blue-700 duration-100">
                    {languageData[404].button}
                </Link>
            </div>
            <DownloadOurApp activeLanguage={activeLanguage} />
        </div>

    )
}

export default NotFound;