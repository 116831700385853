import React from 'react';

interface DriverFormProgressBarProps {
    currentStep: number;
    totalSteps: number;
    className?: string;
}

const DriverFormProgressBar: React.FC<DriverFormProgressBarProps> = ({ currentStep, totalSteps, className = '' }) => {
    // Calculate the width of the progress bar as a percentage
    const progress = (currentStep / totalSteps) * 100;

    return (
        <>

            <div className={`absolute top-4 px-4 h-4  w-full  flex-row hidden md:flex ${className}`}>
                {Array.from({ length: totalSteps }).map((_, i) => (
                    <div key={i} className={`h-full flex-1 border border-white border-2 rounded-lg ${i < currentStep ? 'bg-totalGreen' : 'bg-white'} ${i < totalSteps - 1 ? 'mr-1.5' : ''}`}>
                    </div>
                ))}
            </div>
            <div className='w-full absolute top-4 px-4 h-3'>
                <div className={`${className} w-full  bg-white rounded-full overflow-hidden md:hidden`}>
                    <div
                        style={{ width: `${progress}%` }}
                        className="bg-totalGreen h-3 rounded-full transition-width duration-300 ease-in-out"
                    ></div>
                </div>
            </div>

        </>

    );
}

export default DriverFormProgressBar;


