import React, { useEffect } from 'react';
import Switch from '../../../utils/Switch/Switch';

interface DriverFormSwitchProps {
    value: boolean;
    setSelectedValue: (value: boolean) => void;
}
const DriverFormSwitch: React.FC<DriverFormSwitchProps> = ({ value, setSelectedValue }) => {
    const [currentValue, setCurrentValue] = React.useState(value);
    
    useEffect(() => {
        setSelectedValue(currentValue);
    }, [currentValue]);

    return (
        <div>
            <Switch
                checked={value}
                onChange={() => setCurrentValue(!currentValue)}
                activeColorClass='bg-totalGreen' 
                inactiveColorClass='bg-neutral-500' 
                innerClass='border-white border rounded-full cursor-pointer'
            />
        </div>
    );
};

export default DriverFormSwitch;
