import React from "react";
import TaxiDriver1 from '../../../../images/downloadTheApp/taxi_driv_1.png'
import TaxiDriver2 from '../../../../images/downloadTheApp/taxi_driv_2.png'
import TaxiCustomer1 from '../../../../images/downloadTheApp/taxi_cust_1.png'
import TaxiCustomer2 from '../../../../images/downloadTheApp/taxi_cust_2.png'

interface StackedImagesProps {
    mode: string;
    className?: string;
}

const StackedImages: React.FC<StackedImagesProps> = ({ mode, className }) => {


    return (
        <>
            {mode === 'passenger' ?
                <div className={`h-[90%] max-w-[65vw] relative h-full w-full min-h-[380px] ${className}`}>
                    <img src={TaxiCustomer1} alt="" className="absolute h-3/4 left-[18%] top-[15%]" />
                    <img src={TaxiCustomer2} alt="" className="absolute h-3/4 left-[42%] top-[10%]" />
                </div>
                :
                <div className={`h-[90%] max-w-[65vw] relative h-full w-full min-h-[380px] ${className}`}>
                    <img src={TaxiDriver1} alt="" className="absolute h-3/4 left-[18%] top-[15%]" />
                    <img src={TaxiDriver2} alt="" className="absolute h-3/4 left-[42%] top-[10%]" />
                </div>
            }
        </>

    )
}

export default StackedImages