import React from 'react';
import {IonIcon} from '@ionic/react';

interface QuestionaireOutroProps {
  // Any props you might need
}

const QuestionaireOutro: React.FC<QuestionaireOutroProps> = () => {
  return (
    <div className='text-center'>
      <h2 className='text-4xl font-bold mb-4'>Σας ευχαριστούμε!</h2>
      <p className='mb-2'>Η δήλωσή σας έχει ολοκληρωθεί με επιτυχία. Σε περίπτωση που χρειατεί περαιτέρω δράση, θα επικοινωνήσουμε μαζί σας στο mail που μας δώσατε.</p>
      <a href="/" className='underline'>Πίσω στην αρχική <IonIcon style={{ 'transform': 'translateY(3px)' }} icon="arrow-forward-outline"/></a>
    </div>
  );
};

export default QuestionaireOutro;