import React from 'react';
import PressHeader from '../../Components/Press/PressHeader/PressHeader';
import DefaultLanguageInterface from '../../interfaces';


const Press: React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
  return (
    <div>
      <PressHeader />
    </div>

  );
};

export default Press;