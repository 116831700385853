import React, { useEffect, useState } from 'react';
import { Input } from "../../../@/components/ui/input";

interface DriverFormFileProps {
    sendFile: (file: File) => void;
    preSelectedFile?: File | null;
}

const DriverFormFile: React.FC<DriverFormFileProps> = ({ sendFile, preSelectedFile = null }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(preSelectedFile);

    useEffect(() => {
        // This effect will update the selectedFile state whenever the preSelectedFile prop changes
        setSelectedFile(preSelectedFile);
    }, [preSelectedFile]); // Dependency array includes preSelectedFile to watch for changes

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setSelectedFile(selected);
                sendFile(selected); // Assuming this function correctly handles the file in the parent component
            } else {
                console.log('Invalid file type. Please select an image.');
                // Optionally, you might want to clear the selection or notify the user here
            }
        }
    };

    return (
        <div>
            <Input 
                id="picture" 
                type="file" 
                onChange={handleFileChange} 
                accept="image/jpeg, image/jpg, image/png, image/gif" 
                selectedFile={selectedFile}
            />
            {/* {selectedFile && (
                <p className="text-white text-sm mt-1 ml-1">Επιλεγμένο Αρχείο: {selectedFile.name}</p>
            )} */}
        </div>
    );
};

export default DriverFormFile;