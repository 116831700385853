import React from 'react';
import './RegistrationForm.css';
import QuestionaireWrapper from '../../Components/DriverForm/QuestionaireWrapper/QuestionaireWrapper';
import DefaultLanguageInterface from '../../interfaces';

const RegistrationForm: React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    return (
        <div>
            <QuestionaireWrapper />
        </div>
    )
};

export default RegistrationForm;