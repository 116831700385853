import React from 'react';
import DefaultLanguageInterface from '../../../interfaces';
import Taxi from '../../../images/Taxi_icons/svgs/Taxi.svg';
import Comfort from '../../../images/Taxi_icons/svgs/comfort.svg';
import Box from '../../../images/Taxi_icons/box.png';
import Executive from '../../../images/Taxi_icons/svgs/Executive-taxi.svg';
import Vantaxi from '../../../images/Taxi_icons/svgs/Yellow Van left.svg';
import BlackVanTaxi from '../../../images/Taxi_icons/svgs/Van-taxi.svg';
import Premium from '../../../images/Taxi_icons/svgs/Premium-taxi.svg';
import StationWagon from '../../../images/Taxi_icons/svgs/Station-Wagon-taxi.svg';

import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';

import './HomeFleet.css';
const HomeFleet: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    const fleet = [
        { title: 'Taxi', image: Taxi, section_style: '', text: 'Όλα τα ταξί άμεσα, χωρίς επιπλέον χρέωση', en_text: 'All taxi categories, without any extra charges.', image_style: 'h-12 bottom-[-4px] right-[-28%] sm:h-16  sm:bottom-[-2px] sm:right-[-26%] flip-horizontal taxi-item-taxi-img' },
        { title: 'Comfort', image: Comfort, section_style: '', text: 'Νεότερα ταξί, διαδρομές με άνεση και ευρυχωρία', en_text: 'Newer taxis, rides with comfort and spaciousness.', image_style: 'h-12  right-[-17%] sm:h-16   bottom-[-4px] sm:right-[-16.5%] flip-horizontal' },
        { title: 'Station Wagon', image: StationWagon, section_style: '', text: 'Μεγάλες αποσκευές, διαδρομές προς και από αεροδρόμιο, λιμάνια', en_text: 'Large luggage, rides to and from the airport, ports.', image_style: 'h-12 right-[-24%]  sm:h-16 bottom-[-6px] sm:right-[-24%] flip-horizontal' },

        { title: 'Premium', image: Premium, section_style: '', text: 'Διαδρομές με στυλ και premium χαρακτήρα', en_text: 'Rides with style and a premium character.', image_style: 'h-12 right-[-24%] sm:h-16 bottom-[-6px] sm:right-[-24%] flip-horizontal' },

        { title: 'Executive', image: Executive, section_style: '', text: 'Πολυτέλεια με την άνεση των E-class', en_text: 'Luxury with the comfort of the E-class.', image_style: 'h-12 sm:h-16 bottom-[-6px] right-[-26%]  right-[-10px] sm:right-[-26%] flip-horizontal' },
        { title: 'Van Taxi', image: Vantaxi, section_style: '', text: 'Άνεση πολλών θέσεων [7-9 θέσεις]', en_text: 'Comfort of many seats [7-9 seats].', image_style: 'h-12 right-[-20%]  sm:h-16 bottom-[-3px] sm:right-[-20%]' },
        { title: 'Van ', image: BlackVanTaxi, section_style: '', text: 'Πολυτέλεια και άνεση πολλών θέσεων [7-9 θέσεις]', en_text: 'Premium rides with many seats [7-9 seats].', image_style: 'h-12 right-[-21%]  sm:h-[4.38rem] bottom-[-6px] sm:right-[-21%] flip-horizontal' },
        { title: 'Box', image: Box, section_style: '', text: 'Άμεση αποστολή σημαντικών εγγράφων, φακέλων και πακέτων έως 20kg', en_text: 'Immediate dispatch of documents, folders, and packages up to 20kg.', image_style: 'h-12 right-[-10%] sm:h-16 bottom-[-3px] sm:right-[-10%]  flip-horizontal taxi-item-box' },
    ]


    return (
        <div className="px-4 xmd:px-0 mb-24 ">
            <div className="max-w-1128 mx-auto">
                <h2 className="text-3xl sm:text-4xl xmd:text-4xl mb-6 sm:mb-4 xmd:mb-10 xmd:text-center text-neutral-800 font-semibold">{languageData.home.home_our_fleet_title}</h2>
            </div>
            <div className="max-w-1128 mx-auto">
                <div className='grid grid-cols-2 sm:grid  sm:grid-cols-2 xmd:grid-cols-4  gap-1.5 sm:gap-2 xmd:gap-3 '>
                    {fleet.map((item, index) => (
                        <div key={index} className={`taxi-cat-item bg-neutral-100 h-44 sm:h-40 ${item.section_style} cursor-pointer hover:scale-105 duration-300 rounded-xl overflow-hidden relative select-none`}>
                            <div className="p-4 h-full text-neutral-800 duration-100 hover:text-main">
                                <h3 className="text-xl sm:text-2xl mb-0.5">{item.title}</h3>
                                <p className='text-neutral-400 text-sm sm:text-base'>{activeLanguage == 'el' ? item.text : item.en_text}</p>
                                <img src={item.image} className={`bg-transparent absolute duration-200 ${item.image_style}`} alt="" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )

}

export default HomeFleet;