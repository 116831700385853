import React, { useState, ChangeEvent } from 'react';
import { IonIcon } from '@ionic/react';
import Switch from '../../../../../utils/Switch/Switch';

interface Props {
    id: number;
    category_description: string;
    category_name: string;
    members: number;
    options: { [key: string]: boolean };
    onChange: (optionName: string, isChecked: boolean, id: number) => void;
}

const PrivilageCategory: React.FC<Props> = ({ category_description, category_name, options, onChange, id }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <div className='mx-4'>
            <div className='px-2 mx-0 my-3 py-2 border border-gray-400 rounded-sm'>
                <li key={id} className='flex items-center cursor-grab select-none w-full'>
                    <div className='flex items-center gap-0'>
                        <IonIcon icon='ellipsis-vertical-outline' />
                        <IonIcon icon='ellipsis-vertical-outline' className='ml--2' />
                    </div>
                    <div className='ml-3'>
                        {category_name}
                    </div>

                    <div className='ml-auto flex px-2 items-center text-gray-600 hover:text-black hover:bg-gray-200 cursor-pointer rounded-sm py-2' onClick={() => setIsOpen(!isOpen)}>
                        {!isOpen ? (
                            <IonIcon icon='pencil-outline' />
                        ) : (
                            <IonIcon icon='toggle' />
                        )}
                    </div>
                </li>
                {isOpen ? (
                    <div className='bg-gray-100 mt-2 pt-4 rounded-sm'>
                        <ul className='flex flex-wrap'>
                            {Object.entries(options).map(([optionName, isChecked]) => (
                                <li key={optionName} className='list-none w-1fourth'>
                                    <label className='flex gap-2'>
                                        <Switch checked={isChecked} onChange={(ch) => onChange(optionName, ch, id)} />

                                        {optionName}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PrivilageCategory;
