import React from 'react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../../@/components/ui/select";

interface AdminAcitonsSelectGroupProps {
    groups?: string[];
    setGroup: (groupVisibilityId: string) => void;
    label: string
}

const AdminAcitonsSelectGroup: React.FC<AdminAcitonsSelectGroupProps> = ({ label, groups, setGroup }) => {

    return (
        <div className='py-2 px-4 flex items-center rounded-b-md gap-2 border-t border-gray-300 bg-gray-100 text-sm'>
        <div className='flex items-center gap-1'>
            <span>{label}</span>
            <Select onValueChange={(option) => setGroup(option)}>
                <SelectTrigger className="w-[180px] outline-none h-8">
                    <SelectValue placeholder="Select User Group" />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem key='3' value='admins' >
                            Admins
                        </SelectItem>
                        <SelectItem key='2' value='moderators' >
                            Moderators
                        </SelectItem>

                        <SelectItem key='1' value='all'>
                            All
                        </SelectItem>

                    </SelectGroup>
                </SelectContent>
            </Select>
        </div>
    </div>
    );
}

export default AdminAcitonsSelectGroup;
