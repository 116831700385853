import React, { useEffect, useRef, useState } from 'react';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import { IonIcon } from '@ionic/react';
import { Button } from '../../../@/components/ui/button';
import AddNews from './AddNews/AddNews';
import { useAuthContext } from '../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../constants';
import AdminNewsItem from './AdminNewsItem/AdminNewsItem';
import { id } from 'date-fns/locale';

export type NewsItem = {
    id: number;
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string | File;
    slug: string;
    date: string;
    active: boolean;
    featured: boolean;
};


const AdminNews = () => {
    const { authTokens } = useAuthContext();


    const [view, setView] = useState<string>('overview');
    const [newsItems, setNewsItems] = useState<NewsItem[]>([]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const toggleView = () => {
        if (view === 'overview') {
            setView('add');
        } else {
            setView('overview');
        }
    }

    const RemoveItem = (id: number) => {
        const filteredItems = newsItems.filter(item => item.id !== id);
        setNewsItems(filteredItems);
    }

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens?.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setNewsItems((prev) => [...prev, {
                            grTitle: item.gr_title,
                            enTitle: item.en_title,
                            grDescription: item.gr_description,
                            enDescription: item.en_description,
                            image: item.image_data,
                            date: item.date,
                            slug: item.slug,
                            active: item.active,
                            featured: item.featured,
                            id: item.id
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        const tempArr: any = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    return (
        <div>
            <AdminMainHeader pageTitle='News' />
            <AdminBox>
                {
                    view === 'overview' ?
                        <div className='px-4'>
                            <div className='flex justify-between items-center mb-4'>
                                <AdimBoxTitle title='News Overview' />
                                <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={toggleView}>
                                    <IonIcon icon="add-outline" className="mr-1 text-lg" />
                                    Add News
                                </Button>
                            </div>
                            <div className="px-4 flex flex-col gap-3 mb-5">
                                {newsItems.map((item, index) => (
                                    <div key={index}>
                                        <AdminNewsItem loading={loadingItems} item={item} onDelete={(id) => RemoveItem(id)}/>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <>
                            <AdimBoxTitle title='Add News' />
                            <AddNews onComplete={toggleView} />
                        </>
                }


            </AdminBox>
        </div>
    )
}



export default AdminNews;