import React, { useState, useEffect } from 'react';
import {IonIcon} from '@ionic/react';
import {ACTIVE_URL} from '../../../constants';

interface QuestionaireIntroProps {
    continueS: string;
    questionaireId: number;
    onButtonClick: (newMode: string) => void;
}

const QuestionaireIntro: React.FC<QuestionaireIntroProps> = ({ continueS, questionaireId, onButtonClick }) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    
    useEffect(() => {
        fetch(`${ACTIVE_URL}/api/questionnaire/${questionaireId}/`)
        .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            setTitle(data.title);
            setDescription(data.description);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
    }, [questionaireId]);

    return (
        <div className="max-w-md flex flex-col gap-6">
            <h1 className="text-4xl font-bold">{title}</h1>
            <p style={{ whiteSpace: 'pre-line' }}>{description}</p>
            <p className="underline cursor-pointer" onClick={() => onButtonClick('question')}>Ξεκινήστε <IonIcon style={{ 'transform': 'translateY(3px)' }} icon="arrow-forward-outline"/></p>
        </div>
    );
}

export default QuestionaireIntro;