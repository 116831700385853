import React, { useState, useEffect } from 'react';
import LegalItemSet from './LegalItemSet/LegalItemSet';
import { useAuthContext } from '../../../utils/useAuthContext';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import { ACTIVE_URL } from '../../../constants';

interface LegalItemSetProps {
    id: number;
    admin_title: string;
    title_gr: string;
    title_en: string;
    description_gr: string;
    description_en: string;
    active: boolean;
}

const AdminLegal = () => {
    const { authTokens } = useAuthContext();
    const [legalItems, setLegalItems] = useState<LegalItemSetProps[]>([]);

    

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_legal_texts/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setLegalItems(data)
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    return (
        <div>
            <AdminMainHeader pageTitle='Legal Texts' />
            {legalItems.map((item, index) => {
                return (
                    <LegalItemSet key={index} id={item.id} admin_title={item.admin_title} title_gr={item.title_gr} title_en={item.title_en} description_gr={item.description_gr} description_en={item.description_en} active={item.active} />
                )
            })}
        </div>
    )
}

export default AdminLegal;
export type { LegalItemSetProps };