import React, { useState, useEffect } from 'react';
import AdminMainHeader from '../../AdminMainHeader/AdminMainHeader';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import { useAuthContext } from '../../../../utils/useAuthContext';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import { ACTIVE_URL } from '../../../../constants';
import CandidtatesTableHeader from './CandidateTableRows/CandidtatesTableHeader';
import CandidtatesTableRow from './CandidateTableRows/CandidtatesTableRow';
import { use } from 'i18next';

interface CandidatesTableProps {
    candidates: any[];
    selected?: number[];
    changeSelected: (id: number) => void;
}

const CandidatesTable:React.FC<CandidatesTableProps> = ({candidates, selected, changeSelected}) => {

    const [selectedRows, setSelectedRows] = useState<number[]>(selected !== undefined ? selected : []);

    useEffect(() => {
        setSelectedRows(selected !== undefined ? selected : []);
    }, [selected]);
    
    const handleSelected = (id: number) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
        changeSelected(id);
    }

    return (
        <div className='mx-4 mt-6 border rounded-sm border-gray-400 shadow'>
            <CandidtatesTableHeader />
            {candidates.map((candidate: any, index: number) => (
                <CandidtatesTableRow
                    key={index}
                    id={candidate.id}
                    selected={(selectedRows.includes(candidate.id)) ? true : false}
                    name={candidate.name}
                    stage={candidate.stage}
                    job_fit_score={candidate.score}
                    date_created={candidate.days_since_date_registered}
                    isLast={index === candidates.length - 1}
                    changeSelected={handleSelected}
                />
            ))}
        </div>
    )
}

export default CandidatesTable;
