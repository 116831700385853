import React from "react";
import DefaultLanguageInterface from "../../interfaces";
import DriverCommonQuestions from "../../Components/Driver/DriverCommonQuestions/DriverCommonQuestions";



const DriverCommonQuestionsPage:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {

    return (
        <div>
            <DriverCommonQuestions activeLanguage={activeLanguage} />
        </div>
    )
}

export default DriverCommonQuestionsPage;