import React, { useState, useEffect } from 'react';
import AdminMainHeader from '../AdminMainHeader/AdminMainHeader';
import AdminBox from '../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../Helpers/AdminBoxTitle/AdimBoxTitle';
import CandidatesTable from './AdminCandidatesTable/AdminCandidatesTable';
import { Button } from '../../../@/components/ui/button';
import { IonIcon } from '@ionic/react';
import AdminAddCandidate from './AdminAddCandidate/AdminAddCandidate';
import Modal from '../../../utils/Modal/Modal';
import { ACTIVE_URL } from '../../../constants';
import { useAuthContext } from '../../../utils/useAuthContext';
import './AdminCandidates.css';

import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue, Root, } from "../../../@/components/ui/select";

const AdminCandidates: React.FC = () => {
    const [notes, setNotes] = useState<number>(293);

    const { authTokens } = useAuthContext();
    const [activeView, setActiveView] = useState<string>('candidates');
    const [allCheckMarkSome, setAllCheckMarkSome] = useState<boolean>(false);
    const [pickAll, setPickAll] = useState<boolean>(false);
    const [pickAllTooltip, setPickAllTooltip] = useState<boolean>(false);
    const [moreTooltip, setMoreTooltip] = useState<boolean>(false);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [candidates, setCandidates] = useState<any[]>([]);
    const [paginationNum, setPaginationNum] = useState<number>(30);
    const [paginationPage, setPaginationPage] = useState<number>(1);
    const [selected, setSelected] = useState<number[]>([]);
    const [downloadTooltip, setDownloadTooltip] = useState<boolean>(false);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredCandidates, setFilteredCandidates] = useState<any[]>([]);

    const startIndex = (paginationPage - 1) * paginationNum;
    let endIndex = startIndex + paginationNum;
    if (endIndex > candidates.length) {
        endIndex = candidates.length;
    }

    const isFirstPage = paginationPage === 1;
    let candidatesToDisplay = candidates.slice(startIndex, endIndex);
    let total_results = candidates.length;
    if (filteredCandidates.length > 0 || searchTerm !== '') {
        if (endIndex > filteredCandidates.length) {
            endIndex = filteredCandidates.length;
        }
        candidatesToDisplay = filteredCandidates.slice(startIndex, endIndex);
        total_results = filteredCandidates.length;

    }
    const isLastPage = candidatesToDisplay.length < paginationNum;



    const handlePageChange = (newPage: number) => {
        setPaginationPage(newPage);
    };

    const changeSelected = (id: number) => {
        if (selected.includes(id)) {
            setSelected(selected.filter((rowId) => rowId !== id).sort((a, b) => a - b));
        } else {
            setSelected([...selected, id].sort((a, b) => a - b));
        }
    }

    const all_ids = candidates.map((candidate) => candidate.id);
    useEffect(() => {
        if (selected.length < all_ids.length && selected.length > 0) {
            setAllCheckMarkSome(true);
        } else {
            setAllCheckMarkSome(false);
        }
    }, [selected])

    const togglePickAll = () => {
        if (!pickAllTooltip) {
            setMoreTooltip(false)
        }
        if (!moreTooltip) {
            setPickAllTooltip(false)
        }
        setPickAll((prevPickAll) => !prevPickAll);
        pickAll === true ? setSelected([]) : setSelected(candidates.map((candidate) => candidate.id));
    };
    const pickAllTrue = () => {
        setPickAll(true);
        setSelected(candidates.map((candidate) => candidate.id));
    }

    const pickAllFalse = () => {
        setPickAll(false);
        setSelected([]);
        setPickAllTooltip(false)
        setMoreTooltip(false)
    }

    const toggleMoreTooltip = () => {
        if (!moreTooltip) {
            setPickAllTooltip(false)
        }
        setMoreTooltip((prevMoreTooltip) => !prevMoreTooltip);
    };
    const togglePickAllToolTip = () => {
        if (!pickAllTooltip) {
            setMoreTooltip(false)
        }
        setPickAllTooltip((pickAllTooltip) => !pickAllTooltip);
    }

    const toggleAddPopup = () => {
        setShowAddPopup(!showAddPopup);
    };

    const setResultsPerPage = (option: string) => {
        setPaginationNum(parseInt(option));
        setPaginationPage(1);
    }

    useEffect(() => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_all_candidates/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setCandidates(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    const handleAddCandidate = (name: string, email: string, phone: string) => {
        const postCandidate = async () => {
            if (!authTokens || !authTokens.access) {
                console.error("Authentication token is missing.");
                return;
            }
            const requestData = {
                name: name,
                email: email,
                phone: phone,
            };
            try {
                const response = await fetch(`${ACTIVE_URL}/api/create_candidate/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    },
                    body: JSON.stringify(requestData),
                });

                if (response.ok) {
                    const data = await response.json();
                    setCandidates(data);
                } else {
                    console.error('Failed to add a candidate. HTTP status:', response.status);
                }
            } catch (error) {
                console.error('Error while adding a candidate:', error);
            }
        };
        postCandidate();
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    useEffect(() => {
        if (/\d/.test(searchTerm)) {
            setFilteredCandidates(candidates.filter((candidate) => candidate.phone.includes(searchTerm)));
        } else {
            setFilteredCandidates(candidates.filter((candidate) => candidate.name.toLowerCase().includes(searchTerm.toLowerCase())));
        }
    }, [searchTerm]);

    return (
        <div className=''>
            <AdminMainHeader pageTitle='Candidates' />
            <AdminBox>
                <AdimBoxTitle title='Candidate Search' />
                <div className='flex select-none'>
                    <div className='p-4'>
                        <div className={`header-count relative ${activeView === 'candidates' ? 'active' : ''}`} onClick={() => setActiveView('candidates')}>
                            <div className='header-count--title'>Candidates</div>
                            <div className='header-count--count'>{candidates.length}</div>
                        </div>
                    </div>

                    {/* <div className='p-4'>
                        <div className={`header-count relative ${activeView === 'notes' ? 'active' : ''}`} onClick={() => setActiveView('notes')}>
                            <div className='header-count--title'>Notes</div>
                            <div className='header-count--count'>{notes}</div>
                        </div>
                    </div> */}
                </div>
                <div className='search-row relative p-4 select-none'>
                    <IonIcon icon='search-outline' className='font-semibold absolute top-7 left-7 ion-icon-item' style={{ transform: 'translateY:(-3px)' }} />
                    <input type="text"
                        className={`w-full p-2 pl-9 mb-4 border-2 rounded text-black no-outline focus:outline-none active:outline-none mt-1`} placeholder='Search candidates by name or phone' value={searchTerm} onChange={handleSearchChange} />
                </div>
                <div className='options-row relative py-1 px-4 ml-4 pl-1 select-none'>
                    <div className='flex justify-between w-full'>
                        <div className='flex items-center'>
                            <div className={`border border-gray-300 shadow rounded-md relative flex items-center justify-center w-fit h-max `}>
                                <label className={`inline-flex items-center cursor-pointer p-2 h-9 ${pickAllTooltip ? 'bg-gray-200' : ''}`}>
                                    <input
                                        type="radio"
                                        className="hidden"
                                    />
                                    {pickAll === true && allCheckMarkSome === false ? (
                                        <span onClick={togglePickAll} className="relative flex items-center justify-center w-4 h-4 bg-blue-500 border-blue-500 rounded cursor-pointer">
                                            <IonIcon icon="checkmark-outline" className="absolute text-white font-semibold" />
                                        </span>
                                    ) : (allCheckMarkSome ? (
                                        <span onClick={pickAllTrue} className="relative flex items-center justify-center w-4 h-4 bg-blue-500 border-blue-500 rounded cursor-pointer">
                                            <IonIcon icon="remove" className="absolute text-white font-semibold" />
                                        </span>
                                    ) : (
                                        <span onClick={togglePickAll} className="relative flex items-center justify-center w-4 h-4 border rounded border-gray-600 cursor-pointer">
                                        </span>
                                    )
                                    )}
                                    {pickAllTooltip === false ? (
                                        <IonIcon icon='caret-down' className='font-semibold text-sm text-gray-600 ml-2' onClick={togglePickAllToolTip} />
                                    ) : (
                                        <IonIcon icon='caret-up' className='font-semibold text-sm text-gray-600 ml-2' onClick={togglePickAllToolTip} />
                                    )}
                                </label>
                                {pickAllTooltip === true ? (
                                    <div className='absolute top-9 left-0 flex-col border mt-2 w-28 bg-white rounded-md'>
                                        <div className='px-2 py-1 rounded-t-md text-sm cursor-pointer hover:bg-gray-100' onClick={pickAllTrue}>All</div>
                                        <div className='border-t px-2 py-1 rounded-b-md text-sm cursor-pointer hover:bg-gray-100' onClick={pickAllFalse}>None</div>
                                    </div>
                                ) : (
                                    null
                                )}
                                {moreTooltip === true ? (
                                    <div className='absolute top-9 right-0 flex-col border mt-2 w-36 bg-white rounded-md'>
                                        <div className='px-2 pt-2 pb-1 text-sm rounded-t-md cursor-pointer hover:bg-gray-100'>
                                            <IonIcon icon='checkmark-outline' className='font-semibold text-sm text-gray-600 ion-icon-small-transform mr-1' /> Add Task
                                        </div>
                                        <div className='px-2 py-2 text-sm border-t cursor-pointer hover:bg-gray-100'>
                                            <IonIcon icon='thumbs-down-outline' className='font-semibold text-sm text-gray-600 ion-icon-small-transform mr-1' /> Disqualify
                                        </div>
                                        <div className='border-t px-2 pb-1 pt-2 text-sm rounded-b-md cursor-pointer hover:bg-gray-100'>
                                            <IonIcon icon='trash-outline' className='font-semibold text-sm text-gray-600 ion-icon-small-transform mr-1' /> Delete
                                        </div>
                                    </div>
                                ) : (
                                    null
                                )}
                                {pickAll || allCheckMarkSome ? (
                                    <>
                                        <div className=' border-l border-gray-300 h-9 py-2 px-2 text-sm text-gray-600 font-semibold cursor-pointer justify-center hover:bg-gray-200 select-none'>
                                            <IonIcon icon="pricetag" className="font-semibold mr-1 ion-icon-default-transform" /> Add Tag
                                        </div>
                                        <div className='border-l border-gray-300 cursor-pointer h-9 py-2 px-2 text-sm text-gray-600 font-semibold hover:bg-gray-200 select-none'>
                                            <IonIcon icon="send" className="font-semibold mr-1 ion-icon-default-transform" /> Send Email
                                        </div>
                                        <div className='border-l border-gray-300 cursor-pointer h-9 py-2 px-2 text-sm text-gray-600 font-semibold hover:bg-gray-200 select-none'>
                                            <IonIcon icon="share-social" className="font-semibold mr-1 ion-icon-default-transform" />Share
                                        </div>

                                        <div className='border-l border-gray-300 h-9 justify-center cursor-pointer text-sm text-gray-600 font-semibold px-2 hover:bg-gray-200 select-none flex items-center rounded-r-sm'>
                                            More
                                            {moreTooltip === false ? (
                                                <IonIcon
                                                    icon='caret-down'
                                                    className='font-semibold text-gray-600 ml-1 select-none ion-icon-small-transform'
                                                    onClick={toggleMoreTooltip}
                                                />
                                            ) : (
                                                <IonIcon
                                                    icon='caret-up'
                                                    className='font-semibold text-gray-600 ml-1 select-none ion-icon-small-transform'
                                                    onClick={toggleMoreTooltip}
                                                />
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    null
                                )}
                            </div>
                            {pickAll || allCheckMarkSome ? (
                                <div className='cursor-pointer hover:bg-gray-200 ml-1 px-0.5 rounded-md' onClick={pickAllFalse}>
                                    <IonIcon
                                        icon='close-outline'
                                        className='font-semibold text-gray-600 select-none text-2xl ion-icon-large-transform'
                                    />
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                        <div className='flex items-center gap-2'>
                            <div className='text-md'>
                                <b>{startIndex + 1} - {endIndex}</b> of <b>{candidatesToDisplay.length}</b> candidates
                            </div>
                            <div className='border border-gray-300 shadow rounded-md flex items-center justify-center h-9'>
                                <button onClick={() => handlePageChange(paginationPage - 1)} disabled={isFirstPage} className='px-2 border-l h-full rounded-l-sm cursor-pointer hover:bg-gray-300 flex items-center' >
                                    <IonIcon
                                        icon='chevron-back-outline'
                                        className={`font-semibold text-gray-600 select-none text-md ${isFirstPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                    />
                                </button>
                                <button onClick={() => handlePageChange(paginationPage + 1)} disabled={isLastPage} className='px-2 border-l h-full rounded-r-sm cursor-pointer hover:bg-gray-300 flex items-center'>
                                    <IonIcon
                                        icon='chevron-forward-outline'
                                        className={`font-semibold text-gray-600 select-none text-md ${isLastPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                    />
                                </button>
                            </div>
                            <div className="shadow">
                                <Button variant="outline" className="px-3 h-9 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={toggleAddPopup}>
                                    <IonIcon icon="add-outline" className="mr-2 text-lg" />
                                    Add Candidates
                                </Button>
                            </div>
                            <div>
                                <Button variant="outline" className='px-3 h-9'><IonIcon icon='arrow-down-outline' className='mr-1 text-md'></IonIcon> Date Created</Button>
                            </div>
                            <div>
                                <Button variant="outline" className='px-3 h-9'><IonIcon icon='cloud-download-outline' className='text-lg'></IonIcon></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <CandidatesTable candidates={candidatesToDisplay} selected={selected} changeSelected={changeSelected} />
                </div>
                <div className='mt-4 px-4 flex justify-between'>
                    <div></div>
                    <div className='flex gap-2 select-none'>
                        <Select onValueChange={(option) => setResultsPerPage(option)} value={paginationNum.toString()}>
                            <SelectTrigger className="w-[185px] outline-none h-9">
                                <div>Results per page:</div>
                                <SelectValue placeholder="Results per page" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectItem key='15' value='15'>
                                        15
                                    </SelectItem>
                                    <SelectItem key='30' value='30'>
                                        30
                                    </SelectItem>
                                    <SelectItem key='60' value='60'>
                                        60
                                    </SelectItem>
                                    <SelectItem key='90' value='90'>
                                        90
                                    </SelectItem>
                                    <SelectItem key='200' value='200'>
                                        200
                                    </SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        <div className='border border-gray-300 shadow rounded-md flex items-center justify-center h-9'>
                            <button onClick={() => handlePageChange(paginationPage - 1)} disabled={isFirstPage} className='px-2 border-l h-full rounded-l-sm cursor-pointer hover:bg-gray-300 flex items-center' >
                                <IonIcon
                                    icon='chevron-back-outline'
                                    className={`font-semibold text-gray-600 select-none text-md ${isFirstPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                />
                            </button>
                            <button onClick={() => handlePageChange(paginationPage + 1)} disabled={isLastPage} className='px-2 border-l h-full rounded-r-sm cursor-pointer hover:bg-gray-300 flex items-center'>
                                <IonIcon
                                    icon='chevron-forward-outline'
                                    className={`font-semibold text-gray-600 select-none text-md ${isLastPage ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
                                />
                            </button>
                        </div>
                    </div>


                </div>
            </AdminBox>
            <Modal show={showAddPopup} onClose={toggleAddPopup} width='w-1/2' titleIcon={<IonIcon icon="person-add" className='text-md ion-icon-default-transform mr-1' />} titleText='Add Candidate'>
                <AdminAddCandidate onClose={toggleAddPopup} onAddCandidate={handleAddCandidate} />
            </Modal>
        </div>
    )
}

export default AdminCandidates;