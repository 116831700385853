import React, { useState, useEffect } from 'react';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import { LegalItemSetProps } from '../AdminLegal';

import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';

import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';

const LegalItemSet: React.FC<LegalItemSetProps> = ({ id, admin_title, title_gr, title_en, description_gr, description_en, active }) => {
    const { authTokens } = useAuthContext();

    const [title_gr_state, setTitleGrState] = useState<string>(title_gr);
    const [title_en_state, setTitleEnState] = useState<string>(title_en);
    const [description_gr_state, setDescriptionGrState] = useState<string>(description_gr);
    const [description_en_state, setDescriptionEnState] = useState<string>(description_en);
    const [active_state, setActiveState] = useState<boolean>(active);

    const [show_disc_gr, setShowDiscGr] = useState<boolean>(false);
    const [show_disc_en, setShowDiscEn] = useState<boolean>(false);



    const hundleTextSubmit = () => {
        const fetchResponses = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/post_legal_item/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    },
                    body: JSON.stringify({
                        id: id,
                        title_gr: title_gr_state,
                        title_en: title_en_state,
                        description_gr: description_gr_state,
                        description_en: description_en_state,
                        active: active_state
                    })
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    };



    return (
        <div>
            <AdminBox>
                <AdimBoxTitle title={admin_title} />
                <div className='flex flex-col gap-2 px-4'>
                    <label className='text-lg text-neutral-800' htmlFor="title_gr">Greek Page Title</label>
                    <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_gr" value={title_gr_state} onChange={(e) => setTitleGrState(e.target.value)} />
                </div>
                <div className='flex flex-col mt-6 gap-2 px-4'>
                    <label className='text-lg text-neutral-800' htmlFor="title_en">English Page Title</label>
                    <input className='outline-none border-b border-b-neutral-300' type="text" id="title_en" value={title_en_state} onChange={(e) => setTitleEnState(e.target.value)} />
                </div>
                <div className={`flex flex-col mt-6 gap-2 px-4`}>
                    <label className='text-lg text-neutral-800' htmlFor="desc_gr">Greek Page Description</label>
                    <CKEditorComponent 
                        className={`${show_disc_gr ? 'max-h-0' : 'max-h-none'}`}
                        initialValue={description_gr_state} 
                        onChange={(value) => setDescriptionGrState(value)} 
                    />
                </div>
                <div className='flex flex-col mt-6 gap-2 px-4'>
                    <label className='text-lg text-neutral-800' htmlFor="desc_en">English Page Description</label>
                    <CKEditorComponent
                        className={` ${show_disc_gr ? 'hidden' : 'block'}`}
                        initialValue={description_en_state} 
                        onChange={(value) => setDescriptionEnState(value)} 
                    />
                </div>
                <div className='px-4 mt-4'>
                    <button className='bg-main hover:bg-blue-700 duration-150 text-white px-4 py-1.5 rounded-md shadow-lg' onClick={() => hundleTextSubmit()}>Save Changes</button>
                </div>
            </AdminBox>
        </div>
    )
}

export default LegalItemSet;