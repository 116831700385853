import React from "react";
import DefaultLanguageInterface from "../../../../interfaces";
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../../@/components/ui/accordion";
import HTMLRenderer from "../../../../utils/HTMLRenderer";


const DefaultPassengerAccordeon: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    return (

        <Accordion type="single" collapsible className="w-full main_accordion">
            <AccordionItem value="popular-questions">
                <AccordionTrigger>{languageData.passenger_faq.q1}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_7a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-8">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_8}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_8a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-9">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q1_9}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q1_9a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="mobile-app">
                <AccordionTrigger>{languageData.passenger_faq.q2}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_7a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-8">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q2_8}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q2_8a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="your-trip">
                <AccordionTrigger>{languageData.passenger_faq.q3}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_6a} />

                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q3_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q3_7a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="comments_complaints">
                <AccordionTrigger>{languageData.passenger_faq.q4}</AccordionTrigger>
                <AccordionContent>
                    <Accordion type="single" collapsible className="w-full secondary_accordion">
                        <AccordionItem value="mobile-app__inner-1">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_1}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_1a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-2">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_2}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_2a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-3">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_3}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_3a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-4">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_4}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_4a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-5">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_5}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_5a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-6">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_6}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_6a} />
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="mobile-app__inner-7">
                            <AccordionTrigger className="passenger_faq_sub_title">{languageData.passenger_faq.q4_7}</AccordionTrigger>
                            <AccordionContent>
                                <HTMLRenderer htmlContent={languageData.passenger_faq.q4_7a} />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </AccordionContent>
            </AccordionItem>
            <AccordionItem value="our_fleet">
                <AccordionTrigger>{languageData.passenger_faq.q5}</AccordionTrigger>
                <AccordionContent>
                    <HTMLRenderer htmlContent={languageData.passenger_faq.q5a} />
                </AccordionContent>
            </AccordionItem>
        </Accordion>

    )

}

export default DefaultPassengerAccordeon;