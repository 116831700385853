import React, {useState} from "react";
import PassengerCommonQuestions from "../../../Components/Passenger/PassengerCommonQuestions/PassengerCommonQuestions";
import DefaultLanguageInterface from "../../../interfaces";

const PassengerFAQPage:React.FC<DefaultLanguageInterface> = (props) => {
    const {activeLanguage} = props;
    return (
        <div>
            <PassengerCommonQuestions activeLanguage={activeLanguage} />
        </div>
    )

}

export default PassengerFAQPage;