import React, { useState, useEffect } from 'react';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';

import UserTab from './UserTab/UserTab';
interface User {
    first_name: string;
    last_name: string;
    email: string;
    username: string;
    group: string;
}

const AdminUserManagement = () => {
    const [users, setUsers] = useState<User[]>([] as User[]);
    const { authTokens } = useAuthContext();

    useEffect(() => {
        const fetchGroups = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_users/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }

        fetchGroups();
    }, []);
    return (
        <div>
            <AdminBox>
                <AdimBoxTitle title='Active Users' />
                {users.map((user, index) => (
                    <UserTab 
                        first_name={user.first_name} 
                        last_name={user.last_name}
                        email={user.email}
                        username={user.username}
                        group={user.group}
                        key={index}
                    />
                ))}
            </AdminBox>
        </div>
    )

}

export default AdminUserManagement;