import React from 'react';
import { Link } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import './MenuSidebarItem.css';

interface MenuSidebarItemProps {
    icon: React.ReactElement;
    title: string;
    text: string;
    path: string;
    onClick?: () => void;
}

const MenuSidebarItem: React.FC<MenuSidebarItemProps> = ({ icon, title, text, path, onClick }) => {
    return (
        <div className='w-full h-16 rounded-md flex menu-sidebar-wrapper' onClick={onClick}>
            <Link to={path} className='flex h-full items-center justify-center w-full rounded-md duration-100 hover:bg-neutral-300 '>
                <div className='flex items-center w-5/6'>
                    <div className='w-1/5 flex justify-center text-xl'>
                        {icon}
                    </div>
                    <div className='w-4/5 px-0.5 flex gap-0.5 flex-col'>
                        <div className='flex items-center gap-5'>
                            <h3 className='text-md text-main'>{title}</h3>

                        </div>
                        <p className='text-xs text-neutral-700'>{text}</p>
                    </div>
                </div>

                <div className='w-1/6 h-full flex items-center justify-center'>
                    <IonIcon className='text-md text-neutral-900 menu-sidebar-goto' icon='chevron-forward-outline' />
                </div>
            </Link>
        </div>

    );
}

export default MenuSidebarItem;