

const AdminRoundImageRow = () => {

    return (
        <div className="flex items-center justify-between px-4 py-2 border-b border-gray-200">

        </div>
    )
}

export default AdminRoundImageRow;