import React, { useContext, useState } from 'react';
import './AdminSideBar.css';
import img from '../../../images/logo_.png';
import { IonIcon } from '@ionic/react';
import AuthContext from '../../../utils/AuthContext';
import { ReactComponent as LogoSvg } from '../../../images/logo.svg';


interface AdminSideBarProps {
    activeScreen: string;
    setActiveScreen: (screen: string) => void;
}

const AdminSideBar: React.FC<AdminSideBarProps> = ({ activeScreen, setActiveScreen }) => {
    const [showDriversFormLinks, setDriversFormLinks] = useState<boolean>(false);

    let { logoutUser } = useContext(AuthContext) as any;

    const hanldeLinkGroupStateChange = (setter: (state: boolean) => void, newValue: boolean) => {
        setter(newValue);
        console.log(showDriversFormLinks)
    }

    const handleMenuItemClick = (screen: string) => {
        setActiveScreen(screen);
    };

    return (
        <div className='admin-sidebar overflow-y-auto'>
            <a href='/' className="flex items-center justify-center admin-logo mt-2 mb-2">
                <LogoSvg fill={`#002fff`} className="w-4/6" />
            </a>
            <div className='sidebar-menu'>
                <div className={`sidebar-menu-item ${activeScreen === 'dashboard' ? 'active' : ''}`} onClick={() => handleMenuItemClick('dashboard')}>
                    <IonIcon icon="home-sharp" className="text-main font-semibold ion-icon-item" /> Dashboard
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'News' ? 'active' : ''}`} onClick={() => handleMenuItemClick('News')}>
                    <IonIcon icon="newspaper" className="text-main font-semibold ion-icon-item" /> News
                </div>
                <div className={`sidebar-menu-item ${activeScreen === 'WebsectionSettings' ? 'active' : ''}`} onClick={() => handleMenuItemClick('WebsectionSettings')}>
                    <IonIcon icon="shield" className="text-main font-semibold ion-icon-item" /> Legal Texts
                </div>
                <div className={`sidebar-menu-item select-none ${activeScreen === 'Microapps' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Microapps')}>
                    <IonIcon icon="extension-puzzle" className="text-main font-semibold ion-icon-item" /> Microapps
                </div>
            </div>
            {/* <div className={`sidebar-menu-item logout ${activeScreen === 'users' ? 'active' : ''}`} onClick={() => handleMenuItemClick('users')}>
                <IonIcon icon="id-card-outline" className="text-main font-semibold ion-icon-item" /> Users
            </div> */}

            {/* <div className={`link-group ${showDriversFormLinks ? 'active' : ''}`}>
                    <div className={`link-group-title flex w-full justify-between ${(showDriversFormLinks || activeScreen === 'Questionnaire' || activeScreen === 'Questions' || activeScreen === 'Results') ? 'active' : ''}`} onClick={() => hanldeLinkGroupStateChange(setDriversFormLinks, !showDriversFormLinks)}>
                        <div>
                            <IonIcon icon="business-sharp" className="text-main font-semibold ion-icon-item" /> Drivers Form
                        </div>
                        <div >
                            {showDriversFormLinks && (
                                <>
                                    <IonIcon icon="chevron-up-outline" className="text-main font-semibold self-end ion-icon-item" />
                                </>
                            )}
                            {!showDriversFormLinks && (
                                <>
                                    <IonIcon icon="chevron-down-outline" className="text-main font-semibold ion-icon-item" />
                                </>
                            )}
                        </div>
                    </div>
                    {showDriversFormLinks && (
                        <>

                            <div className={`sidebar-menu-item ${activeScreen === 'Questionnaire' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Questionnaire')}>
                                <IonIcon icon="document-text" className="text-main font-semibold ion-icon-item" /> Questionnaire Overview
                            </div>
                            <div className={`sidebar-menu-item ${activeScreen === 'Questions' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Questions')}>
                                <IonIcon icon="albums" className="text-main font-semibold ion-icon-item" /> Form Questions
                            </div>
                            <div className={`sidebar-menu-item ${activeScreen === 'Results' ? 'active' : ''}`} onClick={() => handleMenuItemClick('Results')}>
                                <IonIcon icon="clipboard-sharp" className="text-main font-semibold ion-icon-item" /> Form Results
                            </div>
                        </>
                    )}
                </div>

                <div className={`sidebar-menu-item ${activeScreen === 'CandidatesMain' ? 'active' : ''}`} onClick={() => handleMenuItemClick('CandidatesMain')}>
                    <IonIcon icon="people" className="text-main font-semibold ion-icon-item" /> Candidates
                </div> */}
            <div className={`sidebar-menu-item logout ${activeScreen === 'settings' ? 'active' : ''}`} onClick={() => handleMenuItemClick('settings')}>
                <IonIcon icon="settings" className="text-main font-semibold ion-icon-item" /> Settings
            </div>
            {/* <div className={`sidebar-menu-item logout ${activeScreen === 'logs' ? 'active' : ''}`} onClick={() => handleMenuItemClick('logs')}>
                <IonIcon icon="code-slash-outline" className="text-main font-semibold ion-icon-item" /> Logs
            </div> */}
            <div className='sidebar-menu-item logout' onClick={logoutUser}>
                <IonIcon icon="log-out" className="text-main font-semibold ion-icon-item" /> Logout
            </div>
        </div>
    )
};

export default AdminSideBar;