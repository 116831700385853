import React, { useEffect, useState } from 'react';
import { NewsItem } from "../AdminNews";
import SkeletonLoader from '../../../../utils/SkeletonLoader/SkeletonLoader';
import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';
import { IonIcon } from '@ionic/react';
import { Button } from '../../../../@/components/ui/button';
import { Input } from '../../../../@/components/ui/input';
import { DatePicker } from '../../Helpers/SimpleDatePicker/SimpleDatePicker';
import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';
import Switch from '../../../../utils/Switch/Switch';

interface AdminNewsItemProps {
    item: NewsItem;
    loading: boolean;
    onDelete: (id: number) => void;
}

const AdminNewsItem: React.FC<AdminNewsItemProps> = ({ item, loading, onDelete }) => {
    const [mode, setMode] = useState<string>('overview');
    const [itemData, setItemData] = useState<NewsItem>(item);

    const { authTokens } = useAuthContext();

    const softDeleteNews = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const response = await fetch(`${ACTIVE_URL}/api/delete_news/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: JSON.stringify({ id: itemData.id }),
            });
            if (response.ok) {
                const data = await response.json();
                onDelete(itemData.id);
            } else {
                console.error('Failed to soft delete responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    const editNewNews = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('id', itemData.id.toString());
            formData.append('gr_title', itemData.grTitle);
            formData.append('en_title', itemData.enTitle);
            formData.append('gr_description', itemData.grDescription);
            formData.append('en_description', itemData.enDescription);
            formData.append('date', itemData.date);
            formData.append('slug', itemData.slug);
            formData.append('featured', itemData.featured ? 'true' : 'false');
            if (itemData.image instanceof File && typeof(itemData.image) !== 'string') {
                formData.append('image', itemData.image);
            } else if (typeof(itemData.image) === 'string') {
                formData.append('image', 'none');
            } else {
                formData.append('image', 'none');
            }
            console.log(itemData.image, typeof(itemData.image));
            console.log(formData.get('image'));

            const response = await fetch(`${ACTIVE_URL}/api/edit_news/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                toggleMode();
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    useEffect(() => {
        setItemData(item);
    }, [item]);

    const toggleMode = () => {
        setMode(mode => mode === 'overview' ? 'edit' : 'overview');
    }

    const setDate = (date: string) => {
        setItemData({ ...itemData, date: date });
    }

    const setImageFile = (file: File) => {
        setItemData({ ...itemData, image: file });
    }

    const resetItem = () => {
        setItemData(item);
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            if (selected.type.startsWith('image/')) {
                setImageFile(selected);
            } else {
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    let imageUrl: string | undefined;

    if (itemData.image instanceof File) {
        imageUrl = URL.createObjectURL(itemData.image);
    } else {
        imageUrl = itemData.image;
    }
    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('data:image/jpeg;base64,${imageUrl}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '298px',
        height: '10rem',
    };

    return (

        <>
            {
                mode === 'overview' ?
                    <div className="hover:shadow-md w-full cursor-pointer select-none rounded-md py-3 px-4 hover:bg-neutral-100 flex items-center justify-between">
                        <div className='w-[90%] flex items-center h-full'>
                            <div className='w-[31%]'>

                                {itemData.date ? new Date(itemData.date).toLocaleDateString('en-GB') : ''}
                            </div>
                            <div className='w-[45%]'>
                                {item.grTitle}
                            </div>
                            <div className='h-full flex items-center gap-1 w-[30%]'>
                                <div className={`h-3 w-3 rounded-full ${itemData.featured ? 'bg-main' : (itemData.active ? 'bg-green-600' : 'bg-red-600')}`}>
                                </div>
                                <div className=''>
                                    {itemData.featured ? 'Featured' : (itemData.active ? 'Active' : 'Inactive')}
                                </div>
                            </div>
                        </div>
                        <div className='w-[20%] flex items-center justify-between'>
                            <div className='w-1/2 flex gap-1 text-neutral-800 hover:text-red-700 items-center' onClick={softDeleteNews}>
                                <p className='text-sm '>Delete</p>
                                <IonIcon icon='trash' className=' text-lg' />
                            </div>
                            <div className='w-1/2 flex items-center gap-1 text-neutral-800 hover:text-main' onClick={toggleMode}>
                                <p className='text-sm '>Edit</p>
                                <IonIcon icon='create' className=' text-xl' />
                            </div>

                        </div>
                    </div>
                    :
                    <>
                        <div className="hover:shadow-md w-full select-none rounded-md py-2.5 shadow-md px-4 bg-neutral-100">
                            <div className='flex items-center justify-between'>
                                <div className='w-[90%] flex items-center h-full'>
                                    <div className='w-[30%]'>
                                        <div className='w-4/5'>
                                            <DatePicker onDateChange={(setDate)} selectedDate={itemData.date} />
                                        </div>

                                    </div>
                                    <div className='w-[45%]'>
                                        <Input
                                            className='h-9 focus-visible:ring-none outline-none ring-offset-0 w-4/5'
                                            placeholder='Greek Title'
                                            value={itemData.grTitle}
                                            onChange={(event) => setItemData({ ...itemData, grTitle: event.target.value })}
                                        />
                                    </div>
                                    <div className='h-full flex items-center text-center gap-1.5 w-[40.1%]'>
                                        <Switch checked={itemData.featured} wrapperClass='mt-1.5' onChange={(ch) => setItemData({ ...itemData, featured: ch })} inactiveColorClass='bg-green-600' />

                                        <div >
                                            {itemData.featured ? 'Featured' : 'Active'}
                                        </div>


                                    </div>
                                </div>
                                <div className='w-[180px] flex items-center justify-between'>
                                    <Button variant="outline" className="px-3 h-9 text-white bg-neutral-600 hover:bg-neutral-800 hover:text-white" onClick={() => { resetItem(); toggleMode(); }}>
                                        <IonIcon icon="close" className="mr-2 text-lg" />
                                        Discard Changes
                                    </Button>

                                </div>
                            </div>

                            <div className='px-2 py-4 flex flex-col gap-5'>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800 ' htmlFor="slug">Slug Address</label>
                                    <input className=' outline-none border-b border-b-neutral-300 w-[300px] py-1 rounded-md px-2.5' type="text" id="title_gr" value={itemData.slug} onChange={(e) => setItemData({ ...itemData, slug: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_en">English Title</label>
                                    <input className=' outline-none border-b border-b-neutral-300 w-[300px] py-1 rounded-md px-2.5' type="text" id="title_en" value={itemData.enTitle} onChange={(e) => setItemData({ ...itemData, enTitle: e.target.value })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_gr">Greek Discription</label>
                                    <CKEditorComponent className=' outline-none border-b border-b-neutral-300' initialValue={itemData.grDescription} onChange={(e) => setItemData({ ...itemData, grDescription: e })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="title_gr">English Discription</label>
                                    <CKEditorComponent className=' outline-none border-b border-b-neutral-300' initialValue={itemData.enDescription} onChange={(e) => setItemData({ ...itemData, enDescription: e })} />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                                    {
                                        itemData.image instanceof File ?
                                            <div className='w-[300px]'>
                                                <img src={imageUrl} className=' rounded-md w-auto' />
                                            </div>
                                            :
                                            <div style={backgroundImageStyle} className='rounded-md'> </div>
                                    }
                                    {/* <div style={backgroundImageStyle} className='rounded-md'> </div> */}
                                    <Input id="picture" type="file" onChange={handleFileChange} accept="image/jpeg, image/jpg, image/png, image/gif" className='w-[300px] cursor-pointer' />
                                </div>
                                <div>
                                    <Button variant="outline" className="px-4 h-10 text-white bg-main hover:bg-blue-600 hover:text-white" onClick={editNewNews}>
                                        <IonIcon icon="cloud-done" className="mr-2 text-lg" />
                                        Confirm Changes
                                    </Button>
                                </div>

                            </div>
                        </div>
                    </>
            }
        </>

    )
}

export default AdminNewsItem;
