import React, { useState } from 'react';
import './EmailQuestion.css';

interface EmailQuestionProps {
  sendEmail: (email: string) => void;
}

const EmailQuestion: React.FC<EmailQuestionProps> = ({ sendEmail }) => {
  const [email, setEmail] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (email.trim() !== '' && emailRegex.test(email)) {
      sendEmail(email);
      setEmail('');
    } else {
      setIsInvalidEmail(true);
    }
  };

  return (
    <div>
      {isInvalidEmail ? (
        <p className="text-white text-sm">Παρακαλώ εισάγετε ένα έγκυρο email.</p>
      ) : null}
      <input
        type="email"
        value={email}
        onChange={handleEmailChange}
        placeholder="Παρακαλώ εισάγετε το email σας..."
        className={`w-full p-2 mb-3 border-2 rounded text-black no-outline ${isInvalidEmail ? 'border-red-500' : 'border-white'
          } focus:outline-none active:outline-none`}
      />

      <button
        onClick={handleSubmit}
        className="px-4 py-2 bg-blue-600 text-white font-semibold rounded transition-colors shadow-md hover:bg-blue-700 focus:outline-none"
      >
        Επόμενο
      </button>
    </div>
  );
};

export default EmailQuestion;
