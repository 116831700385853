import React from 'react';
import { IonIcon } from '@ionic/react';
import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';

interface AdminActionHeaderProps {
    title: string;
    icon: React.ReactNode;
    closeAction: () => void;
    className?: string;
}

const AdminActionHeader: React.FC<AdminActionHeaderProps> = ({ title, icon, closeAction, className}) => {
    return (
        <div className={` ${className} flex justify-between items-center px-2 py-1 bg-gray-100 border-b border-gray-200 py-2 px-4 text-gray-700`}>
            <div className='flex gap-2 items-center'>
                <div className='flex items-center'>{icon}</div>
                <span className='text-sm'>{title}</span>
            </div>
            <div className='hover:bg-red-600 cursor-pointer px-2 flex items-center py-2 rounded-sm hover:text-white' onClick={closeAction}>
                <IonIcon icon="trash" />
            </div>
        </div>
    );
};

export default AdminActionHeader;