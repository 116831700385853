import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DriverHeader.css';
import LogoSvg from '../../../images/LogoSvg';

import DefaultLanguageInterface from '../../../interfaces';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import grLoc from '../../../images/Taxi_icons/svgs/gr_loc.svg'

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
    Root,
} from "../../../@/components/ui/select";
import { Link } from 'react-router-dom';
import ActionButton from '../../ActionButton/ActionButton';
import Switch from '../../../utils/Switch/Switch';


const DriverHeader: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const navigate = useNavigate();

    const [city, setCity] = React.useState<string>('Αθήνα');
    const [isTACCheckboxChecked, setIsTACCheckboxChecked] = React.useState<boolean>(true);

    const [email, setEmail] = useState('');
    const [emailFieldTouched, setEmailFieldTouched] = useState(false);
    const [isEmailFieldFocused, setIsEmailFieldFocused] = useState(false);

    const [phoneFieldTouched, setPhoneFieldTouched] = useState(false);
    const [isPhoneFieldFocused, setIsPhoneFieldFocused] = useState(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const [phoneNumber, setPhoneNumber] = useState('');

    const isValidEmail = (email: string) => {
        const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return pattern.test(email);
    };

    const isValidPhoneNumber = (number: string) => {
        const pattern = /^\+?\d{10,10}$/;
        return pattern.test(number);
    };

    const emailBlurHandler = () => {
        setEmailFieldTouched(true);
        setIsEmailFieldFocused(false);
    }

    const phoneBlurHandler = () => {
        setPhoneFieldTouched(true);
        setIsPhoneFieldFocused(false);
    }

    const handleSubmit = () => {
        // if (isValidEmail(email) && isValidPhoneNumber(phoneNumber) && isTACCheckboxChecked) {
        if (isTACCheckboxChecked) {
            navigate('/drivers-form', { state: { email, phoneNumber, city } });
        } else {
            console.error("Form is not valid or checkbox is not checked.");
        }
    };

    return (
        <div className=" driver_header-container bg-contain bg-driver-header-mobile xmd:bg-driver-header-desktop xmd:h-[78vh] flex rounded-b-lg xmd:rounded-none  xmd:pl-4 1240:pl-0 relative ovefrlow-x-hidden">
            <div className="max-w-1128  w-full xmd:h-full flex flex-col xmd:flex-row items-center  text-white m-auto xmd:justify-center relative ovefrlow-x-hidden">
                <div className='w-full h-[43vh] xmd:h-full xmd:w-1/2  px-4 justify-center xmd:justify-center flex-col xmd:flex'>
                    <div className='text-[2.2rem] absolute top-[345px] xmd:mt-[30%] tracking-tight xmd:top-0 xmd:relative font-semibold xmd:text-6xl animate-fadeUp xmd:pb-6 pb-5 leading-tight w-5/6 xmd:w-full xmd:max-w-[520px]'>
                        <span>{languageData.driver.header_title}</span>
                        <LogoSvg fill={'#fff'} pathStyle="duration-200 transition-property-fill" className="h-[2.2rem] sm:h-[3rem] xmd:h-[3.5rem] mt-2.5 sm:mt-4 ml-1" />
                    </div>
                    <div className='delay-75 absolute top-[472px] xmd:top-0 sm:top-[460px] xmd:relative animate-fadeUp mb-4 xmd:mb-6 font-thin text-lg xmd:text-xl leading-8  w-4/5 xmd:w-full xmd:min-w-[400px] xmd:w-[28vw]'>
                        {languageData.driver.header_subtitle}
                    </div>

                </div>
                <div className='w-screen xmd:w-1/2 mt-64 xmd:animate-fadeUp'>
                    <div className='xmd:mr-[25px] flex xmd:justify-end  z-50'>
                        <div className='bg-white rounded-lg py-8 px-6 md:shadow-md w-full xmd:w-[500px] text-primary select-none shadow-lg'>
                            <h2 className='text-[2.3rem] text-neutral-800 text-bold mb-6'>{languageData.driver.driver_form_intro_title}</h2>
                            <form className='flex flex-col gap-8'>
                                <div className='w-full flex flex-col gap-1'>
                                    <label htmlFor="email" className='text-sm font-bold ml-1'>Email</label>
                                    <input
                                        type="email"
                                        id="email"
                                        placeholder={languageData.driver.driver_form_intro_email_placeholder}
                                        className={`w-full text-[0.95rem] h-12 pl-2 pr-2 text-neutral-900 rounded-md outline-none bg-neutral-100 border ${isValidEmail(email) && emailFieldTouched ? 'border-green-400' : (!emailFieldTouched ? 'border-neutral-200' : 'border-red-500')} focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                                        value={email}
                                        onBlur={() => setEmailFieldTouched(true)}
                                        onFocus={() => setEmailFieldTouched(false)}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                {!isValidEmail(email) && emailFieldTouched && !isEmailFieldFocused && (
                                    <div className="absolute ml-0 pl-1  mt-16 pt-3 text-xs text-red-500">
                                        {languageData.driver.driver_form_intro_email_error}
                                    </div>
                                )}
                                <div className='w-full flex flex-col gap-1'>
                                    <label htmlFor="tel" className='text-sm font-bold ml-1'>{languageData.driver.driver_form_intro_phone_title}</label>
                                    <div className='flex gap-2 w-full'>
                                        <div className='h-12 w-1/6 text-black rounded-md outline-none bg-neutral-100 border flex items-center justify-center gap-1'>
                                            <img src={grLoc} alt="greek flag" className='h-4' />
                                            <div className='text-neutral-900 text-lg'>
                                                +30
                                            </div>
                                        </div>
                                        <input
                                            type="tel"
                                            id='tel'
                                            placeholder={languageData.driver.driver_form_intro_phone_placeholder}
                                            onBlur={() => phoneBlurHandler()}
                                            className={`w-5/6 text-[0.95rem] h-12 px-2 text-black rounded-md outline-none bg-neutral-100 border ${isValidPhoneNumber(phoneNumber) && phoneFieldTouched ? 'border-green-400' : (!phoneFieldTouched ? 'border-neutral-200' : (!isPhoneFieldFocused ? 'border-red-500' : ''))} focus:pl-[6.68px] focus:border-2 focus:border-main focus:shadow-md`}
                                            value={phoneNumber}
                                            onInput={(e) => {
                                                const target = e.target as HTMLInputElement;
                                                let value = target.value.replace(/\D+/g, '');
                                                if (value.length > 10) {
                                                    value = value.substring(0, 10);
                                                }
                                                target.value = value;
                                            }}
                                            onFocus={() => setIsPhoneFieldFocused(true)}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                        {!isValidPhoneNumber(phoneNumber) && phoneFieldTouched && !isPhoneFieldFocused && (
                                            <div className="absolute ml-20 pl-1.5 mt-12 pt-1 text-xs text-red-500">
                                                {languageData.driver.driver_form_intro_phone_error}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='w-full flex flex-col gap-1 select-none'>
                                    <label htmlFor="city" className='text-sm font-bold ml-1'>{languageData.driver.driver_form_intro_city_title}</label>
                                    <Select onValueChange={(option) => setCity(option)} value={city} >
                                        <SelectTrigger className=" outline-none h-12 text-neutral-600 ">
                                            <SelectValue placeholder="Επίλεξε Πόλη" />
                                        </SelectTrigger>
                                        <SelectContent className='w-full '>
                                            <SelectGroup className=''>
                                                <SelectItem key='1' value='Αθήνα' className='hover:text-main'>
                                                    <div className='hover:text-main'>
                                                    {activeLanguage === 'el' ? 'Αθήνα' : 'Athens'}
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key='2' value='Θεσσαλονίκη' >
                                                    <div className='hover:text-main'>
                                                        {activeLanguage === 'el' ? 'Θεσσαλονίκη' : 'Thessaloniki'}
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key='3' value='Πάτρα' >
                                                    <div className='hover:text-main'>
                                                        {activeLanguage === 'el' ? 'Πάτρα' : 'Patra'}
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key='4' value='Ιωάννινα' >
                                                    <div className='hover:text-main'>
                                                        {activeLanguage === 'el' ? 'Ιωάννινα' : 'Ioannina'}
                                                    </div>
                                                </SelectItem>
                                                <SelectItem key='5' value='Κρήτη' >
                                                    <div className='hover:text-main'>
                                                        {activeLanguage === 'el' ? 'Κρήτη' : 'Crete'}
                                                    </div>
                                                </SelectItem>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div className='text-xs text-neutral-500 flex flex-col gap-4'>
                                    <div className='flex gap-3 items-center '>
                                        <Switch checked={isTACCheckboxChecked} onChange={() => setIsTACCheckboxChecked(!isTACCheckboxChecked)} activeColorClass='bg-main' inactiveColorClass='bg-neutral-500' wrapperClass='w-12 h-6' />
                                        <div className='text-neutral-700'>
                                        {activeLanguage === 'el' ? 'Με την εγγραφή σας συμφωνείτε με τους Όρους Χρήσης και την Πολιτική Απορρήτου μας, δεσμεύεστε να συμμορφώνεστε με τις υποχρεώσεις σύμφωνα με την Ευρωπαϊκή Ένωση και την τοπική νομοθεσία και να παρέχετε μόνο νόμιμες υπηρεσίες και περιεχόμενο στην πλατφόρμα taxi.gr' : 'By signing up, you agree to our Terms of Service and Privacy policy, commit to comply with obligations under the European Union and local legislation and provide only legal services and content on the taxi.gr platform.'}

                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ActionButton className='btn btn-primary bg-main py-[0.565rem] px-6 rounded-md hover:bg-secondary duration-10 text-white' activeLanguage={activeLanguage} text_english={'Sign Up'} text_greek={'Κάνε Εγγραφή'} onClick={handleSubmit} />
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )

}

export default DriverHeader;