import React, { useEffect } from 'react';
import HeroSection from '../../Components/Home/HomeHero/HomeHero';
import HomeDriver from '../../Components/Home/HomeDriver/HomeDriver';
import HomeLoveTheRide from '../../Components/Home/HomeLoveTheRide/HomeLoveTheRide';
import HomeFAQ from '../../Components/Home/HomeFAQ/HomeFAQ';
import WhyUs from '../../Components/Home/WhyUs/WhyUs';
import DownloadOurApp from '../../Components/Home/DownloadOurApp/DownloadOurApp';
import HomeFleet from '../../Components/Home/HomeFleet/HomeFleet';
import HomeNews from '../../Components/Home/HomeNews/HomeNews';


interface HomeProps {
  activeLanguage: string;
}

const Home: React.FC<HomeProps> = (props) => {
  const { activeLanguage } = props;

  return (
    <div>

      <HeroSection activeLanguage={activeLanguage} />

      <HomeLoveTheRide activeLanguage={activeLanguage} />
      
      <HomeFleet activeLanguage={activeLanguage} />

      <HomeDriver activeLanguage={activeLanguage} />
      {/* <WhyUs activeLanguage={activeLanguage} /> */}


      <HomeNews activeLanguage={activeLanguage} />

      <DownloadOurApp activeLanguage={activeLanguage} />


      {/* <HomeFAQ /> */}
    </div>
  );
};

export default Home;