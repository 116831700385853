import React, { useEffect, useState } from 'react';
import { ACTIVE_URL } from '../../../../constants';
import DefaultLanguageInterface from '../../../../interfaces';
import HTMLRenderer from '../../../../utils/HTMLRenderer';

const PrivacyPolicyContent:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {

    const [privacyPolicyContentGr, setPrivacyPolicyContentGr] = useState<string>('');
    const [privacyPolicyContentEn, setPrivacyPolicyContentEn] = useState<string>('');

    useEffect(() => {

        const fetchResponses = async () => {
            window.scrollTo(0, 0);
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_pp_text/`, {
                    method: 'GET',
                });
                if (response.ok) {
                    const data = await response.json();
                    setPrivacyPolicyContentGr(data.description_gr);
                    setPrivacyPolicyContentEn(data.description_en);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    return (
        <div className='max-w-1128 m-auto pt-12 pb-10 px-4  1180:px-0 xmd:pt-20 xmd:pb-36 '>
            <HTMLRenderer htmlContent={activeLanguage === 'el' ? privacyPolicyContentGr : privacyPolicyContentEn} />
        </div>
    )
}

export default PrivacyPolicyContent;