import React from 'react';
import TermsAndConditionsHero from '../../../Components/Misc/TermsAndConditions/TermsAndConditionsHero/TermsAndConditionsHero';
import TermsAndConditionsContent from '../../../Components/Misc/TermsAndConditions/TermsAndConditionsContent/TermsAndConditionsContent';
import DefaultLanguageInterface from "../../../interfaces";

const TermsAndConditionsPage:React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {

    return (
        <div>
            <TermsAndConditionsHero activeLanguage={activeLanguage} />
            <TermsAndConditionsContent activeLanguage={activeLanguage} />
        </div>
    )

}


export default TermsAndConditionsPage;