import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

// Define Facebook Pixel
const FacebookPixel = {
  init(pixelId: string) {
    const script = document.createElement('script');
    script.text = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(script);
  },
  track(event: string, data?: object) {
    if ((window as any).fbq && typeof (window as any).fbq === 'function') {
      (window as any).fbq('track', event, data);
    }
  }
};

// Define TikTok Pixel
const TikTokPixel = {
  init(pixelId: string) {
    const script = document.createElement('script');
    script.text = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;
        var ttq=w[t]=w[t]||[];
        ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],
        ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
        for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);
        ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},
        ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";
        ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
        var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
        var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('${pixelId}');
        ttq.page();
      }(window, document, 'ttq');
    `;
    document.head.appendChild(script);
  },
  track(event: string, data?: object) {
    if ((window as any).ttq && typeof (window as any).ttq.track === 'function') {
      (window as any).ttq.track(event, data);
    }
  }
};

// Initialize Google Analytics
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize("G-G9SKXHHVH9");
}

// Initialize Facebook Pixel
FacebookPixel.init('526176992904010');

// Initialize TikTok Pixel
TikTokPixel.init('CQ61QRJC77U6L0AM1GG0');

i18n.use(initReactI18next).use(HttpApi).init({
  backend: {
    loadPath: '/locales/{{lng}}.json', // Path to your translation files
  },
  fallbackLng: 'gr',
  lng: 'gr', // Set the default language here
  interpolation: {
    escapeValue: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();