import React, { useEffect } from "react";
import DefaultLanguageInterface from "../../interfaces";
import DriverFormNew from "../../Components/DriverFormNew/DriverFormNew";
import { useLocation } from 'react-router-dom';

const DriversFormNewPage:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {
    const location = useLocation();
    const { email, phoneNumber, city } = location.state || { email: '', phone_number: '', city: '' };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="">
            <DriverFormNew activeLanguage={activeLanguage} email={email} phone_number={phoneNumber} city={city} />
        </div>
    )
}

export default DriversFormNewPage;