import React from 'react';
import PrivacyPolicyHero from '../../../Components/Misc/PrivacyPolicy/PrivacyPolicyHero/PrivacyPolicyHero';
import PrivacyPolicyContent from '../../../Components/Misc/PrivacyPolicy/PrivacyPolicyContent/PrivacyPolicyContent';
import DefaultLanguageInterface from "../../../interfaces";

const PrivacyPolicyPage:React.FC<DefaultLanguageInterface> = (props) => {
    const {activeLanguage} = props;
    return (
        <div>
            <PrivacyPolicyHero activeLanguage={activeLanguage}/>
            <PrivacyPolicyContent activeLanguage={activeLanguage}/>

        </div>
    )

}


export default PrivacyPolicyPage;