import React, { useState, useEffect } from 'react';
import CKEditorComponent from '../../../../utils/ckEditor/CKEditorComponent';
import { useAuthContext } from '../../../../utils/useAuthContext';
import { ACTIVE_URL } from '../../../../constants';
import { Input } from '../../../../@/components/ui/input';
import { IonIcon } from '@ionic/react';
import { DatePicker } from '../../Helpers/SimpleDatePicker/SimpleDatePicker';

interface AddNewsProps {
    onComplete: () => void;

}

const AddNews: React.FC<AddNewsProps> = ({ onComplete }) => {
    const { authTokens } = useAuthContext();
    const [grTitle, setGrTitle] = useState<string>('');
    const [enTitle, setEnTitle] = useState<string>('');
    const [grDescription, setGrDescription] = useState<string>('');
    const [enDescription, setEnDescription] = useState<string>('');
    const [slug, setSlug] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [date, setDate] = useState<string>('');

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            const selected = files[0];
            // Check if the selected file is an image (you can customize the accepted image types)
            if (selected.type.startsWith('image/')) {
                setSelectedFile(selected);
            } else {
                // Display an error message or handle invalid file type
                console.log('Invalid file type. Please select an image.');
            }
        }
    };

    const submitNewNews = async () => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const formData = new FormData();
            formData.append('gr_title', grTitle);
            formData.append('en_title', enTitle);
            formData.append('gr_description', grDescription);
            formData.append('en_description', enDescription);
            formData.append('date', date);
            formData.append('slug', slug);


            if (selectedFile) {
                formData.append('image', selectedFile);
            }

            const response = await fetch(`${ACTIVE_URL}/api/post_news/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                setGrTitle('');
                setEnTitle('');
                setGrDescription('');
                setEnDescription('');
                setDate('');
                setSelectedFile(null);
                onComplete();
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    }

    return (
        <div className='px-4 flex flex-col gap-4'>
            <div className='flex flex-col mt-6 gap-2 mb-4'>
                <label className='text-lg text-neutral-800 ' htmlFor="desc_en">Displayed Date</label>
                <DatePicker onDateChange={setDate} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800 ' htmlFor="slug">Slug Address</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_gr" value={slug} onChange={(e) => setSlug(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="picture">Display Image</label>
                <Input id="picture" type="file" onChange={handleFileChange}  accept="image/jpeg, image/jpg, image/png, image/gif" />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="title_gr">Greek Title</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_gr" value={grTitle} onChange={(e) => setGrTitle(e.target.value)} />
            </div>
            <div className='flex flex-col gap-2'>
                <label className='text-lg text-neutral-800' htmlFor="title_en">English Title</label>
                <input className=' outline-none border-b border-b-neutral-300' type="text" id="title_en" value={enTitle} onChange={(e) => setEnTitle(e.target.value)} />
            </div>
            <div className={`flex flex-col gap-2 mb-4`}>
                <label className='text-lg text-neutral-800' htmlFor="desc_gr">Greek Page Description</label>
                <CKEditorComponent
                    className='h-40'
                    initialValue={grDescription}
                    onChange={(value) => setGrDescription(value)}
                />
            </div>
            <div className='flex flex-col mt-6 gap-2 mb-4'>
                <label className='text-lg text-neutral-800 ' htmlFor="desc_en">English Page Description</label>
                <CKEditorComponent
                    className='h-40'
                    initialValue={enDescription}
                    onChange={(value) => setEnDescription(value)}
                />
            </div>

            <div className='mt-5'>
                <button onClick={submitNewNews} className="flex items-center px-4 py-2 bg-blue-600 text-white cursor-pointer mt-1 text-m font-semibold rounded transition-colors shadow-md hover:bg-blue-700 focus:outline-none">
                    <IonIcon icon="add-outline" className="mr-1 text-lg" />
                    Add News
                </button>
            </div>

        </div>
    )
}


export default AddNews;