import { IonIcon } from "@ionic/react";
import './UndecitedThumbs.css';

interface UndecitedThumbsProps {
    className?: string;
}

const UndecitedThumbs: React.FC<UndecitedThumbsProps> = ({ className }) => {
    return (
        <div className={`text-xs thumbs ${className}`}>
            <IonIcon icon="thumbs-up" className="like-thumb"/>
            <IonIcon icon="thumbs-down" className="dislike-thumb"/>
        </div>
    )
}

export default UndecitedThumbs;