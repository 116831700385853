import React, { useEffect, useRef, useState } from 'react';
import DefaultLanguageInterface from '../../../../interfaces';
import greekLanguage from '../../../../locales/gr.json';
import englishLanguage from '../../../../locales/en.json';
import { ACTIVE_URL } from '../../../../constants';
import HTMLRenderer from '../../../../utils/HTMLRenderer';
import SkeletonLoader from "../../../../utils/SkeletonLoader/SkeletonLoader";
import { IonIcon } from '@ionic/react';
import { Link } from 'react-router-dom';

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    date: string;
    slug: string;
};

const NewsHomeHeader: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [newsItem, setNewsItem] = useState<NewsItem>({ grTitle: '', enTitle: '', grDescription: '', enDescription: '', image: '', date: '', slug: '' });
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;
    const loading = useRef(true);

    const backgroundImageStyle = {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url('data:image/jpeg;base64,${newsItem.image}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // width: '100%',
        height: '54vh',
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchResponses = async () => {

            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_featured_news/`, {
                    method: 'GET',
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItem({
                        grTitle: data.gr_title,
                        enTitle: data.en_title,
                        grDescription: data.gr_description,
                        enDescription: data.en_description,
                        image: data.image_data,
                        slug: data.slug,
                        date: data.date
                    });
                    loading.current = false;
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };
        fetchResponses();
    }, []);

    return (
        <div className='py-8 px-4 xmd:py-16'>
            <div className='py-11 max-w-1128 m-auto'>
                <h1 className='text-4xl xmd:text-5xl  text-neutral-800 text-center'>{languageData.home.home_news}</h1>
            </div>
            {loading.current ?
                <div className='max-w-1128 m-auto'>
                    <SkeletonLoader height={'54vh'} width={'100%'} borderRadius={14} />
                </div>
                :
                <div style={backgroundImageStyle} className='text-white max-w-1128 mx-auto px-6 pb-4 xmd:px-10 rounded-2xl select-none'>
                    <div className="max-w-1128 animate-fadeUp w-full h-full flex flex-col justify-end text-white m-auto xmd:justify-center">
                        <div className='text-sm xmd:text-base'>{languageData.news.featured}</div>
                        <div className='text-2xl xmd:text-4xl font-semibold xmd:pb-4 pb-2 leading-tight xmd:max-w-[520px]'>{activeLanguage === 'el' ? newsItem?.grTitle : newsItem?.enTitle}</div>
                        <div className='text-base  mb-4 xmd:mb-6 font-thin xmd:text-lg leading-8 w-full xmd:min-w-[400px] xmd:w-[32vw]'>
                            {newsItem?.date ? new Date(newsItem.date).toLocaleDateString('en-GB') : ''}
                        </div>
                        <div className='flex'>
                            <Link to={'/news/' + newsItem.slug} className='delay-100  bg-main px-3 py-1.5 xmd:py-2 xmd:px-5 rounded-full hover:bg-secondary flex items-center gap-2 cursor-pointer'>
                                <p className='flex '>Read More</p>
                                <IonIcon icon='arrow-forward-outline' className='mt-0.5' />
                            </Link>
                        </div>

                    </div>
                </div>
            }
        </div>
    )
}

export default NewsHomeHeader;