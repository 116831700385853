import bs from '../../images/driver_form/bs.jpg'
import bs2 from '../../images/driver_form/bs_2.jpg'
import cs from '../../images/driver_form/cs.jpg'
import dl from '../../images/driver_form/dl.jpg'
import dlc from '../../images/driver_form/dlc.png'
import dlc_back from '../../images/driver_form/dlc_back.png'
import fa from '../../images/driver_form/fa.jpg'
import id from '../../images/driver_form/id.png'
import id_back from '../../images/driver_form/id_back.png'
import ti from '../../images/driver_form/ti.png'
import pi from '../../images/driver_form/pi.jpg'

export type DriverInfo = {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    city: string;
    personal_info_consent: boolean;

    receipt: File | null;
    edx_certificate: File | null;
    vehicle_registration: File | null;
    id_front: File | null;
    id_back: File | null;
    driver_license_front: File | null;
    driver_license_back: File | null;
    profile_image: File | null;
    bank_receipt: File | null;
    vehicle_image: File | null;
}


export type DriverInfoMetadata = {
    [Property in keyof DriverInfo]: {
        questionType: 'string' | 'phone' | 'city-select' | 'file' | 'boolean' | 'email';
        title: string;
        description: string;
        position: number;
        image_page?: string;
        questionText?: string;
        skippable?: boolean;
    };
};

const fa_text = 'Στη φωτογραφία που θα μας στείλεις πρέπει να φαίνονται καθαρά το ονοματεπώνυμο του εκμεταλλευτή της ταμειακής μηχανής και ο αριθμός φορολογικού μητρώου (ΑΦΜ) του. Υπάρχει περίπτωση να ζητήσουμε να στείλεις ξανά φωτογραφία του εντύπου, εάν κάποια από τις παραπάνω πληροφορίες λείπει ή δεν είναι σαφής.';
const pi_text = 'Χρειαζόμαστε μια φωτογραφία (τύπου selfie), την οποία θα εισάγουμε στο προφίλ σου και θα είναι το πρώτο πράγμα που βλέπει ο πελάτης μόλις αποδεχτείς τη διαδρομή. Για μια καλή φωτογραφία για το προφίλ σου, τοποθέτησε το κινητό σου στο ύψος των ώμων, χαμογέλα και κοίτα ευθεία στην κάμερα. Θυμήσου να μην φοράς γυαλιά ηλίου! Προτίμησε φυσικό φωτισμό και ένα μονόχρωμο φόντο. Δεν δεχόμαστε φωτογραφίες προφίλ τραβηγμένες από άλλες φωτογραφίες ή έγγραφα, όπως η ταυτότητα και το δίπλωμα, ή από οθόνες.';
const dl_text = 'Στη φωτογραφία που θα μας στείλεις, πρέπει να φαίνονται καθαρά και οι τρεις σελίδες της άδειας κυκλοφορίας. Για την εγγραφή σου, χρειάζεται να δούμε την ημερομηνία κυκλοφορίας, το μοντέλο και τη μάρκα του οχήματος (1η σελίδα), όπως επίσης και τα στοιχεία του ιδιοκτήτη (2η σελίδα). Τέλος, πρέπει να είναι ευκρινής ο αριθμός κυκλοφορίας του οχήματος (3η σελίδα). Υπάρχει περίπτωση να ζητήσουμε να στείλεις ξανά φωτογραφία του εντύπου, εάν κάποια από τις παραπάνω πληροφορίες λείπει ή δεν είναι σαφής.'
const edx_text = 'Στη φωτογραφία που θα μας στείλεις πρέπει να φαίνονται καθαρά ο αριθμός της ειδικής άδειας οδήγησης ΕΔΧ, η ημερομηνία λήξης και τα στοιχεία κατόχου. Εάν υπάρχουν ανανεώσεις/επεκτάσεις, χρειάζεται να φωτογραφηθούν ευκρινώς κι αυτές. Υπάρχει περίπτωση να ζητήσουμε να στείλεις ξανά φωτογραφία του εντύπου, εάν κάποια από τις παραπάνω πληροφορίες λείπει ή δεν είναι σαφής.'
const id_text = 'Χρειάζεται να μας στείλεις φωτογραφία της μπροστινής όψης της αστυνομικής ταυτότητας ή του διαβατηρίου σου. Στη φωτογραφία της ταυτότητας πρέπει να φαίνεται καθαρά ο αριθμός του δελτίου ταυτότητας. Στη φωτογραφία του διαβατηρίου πρέπει να φαίνονται καθαρά όλα τα στοιχεία του κατόχου. Επίλεξε καλό (φυσικό) φωτισμό και δώσε ιδιαίτερη προσοχή στο να μην γυαλίζουν οι φωτογραφίες και να μην κόβονται οι γωνίες. Τέλος, το έγγραφο πρέπει να σταλεί σε μορφή φωτογραφίας και όχι σκαναρισμένης φωτοτυπίας. Υπάρχει περίπτωση να ζητήσουμε να στείλεις ξανά φωτογραφία του εντύπου, εάν κάποια από τις παραπάνω πληροφορίες λείπει ή δεν είναι σαφής.'


export const driverInfoMetadataItems: DriverInfoMetadata = {
    email: { questionType: 'email', title: 'Διεύθυνση Email', description: 'Email Address', position: 0, skippable: false},
    phone_number: { questionType: 'phone', title: 'Αριθμός τηλεφώνου', description: 'Phone Number', position: 1, skippable: false },
    city: { questionType: 'city-select', title: 'Πόλη', description: 'City', position: 2, skippable: false },
    first_name: { questionType: 'string', title: 'Όνομα', description: 'Όνομα', position: 3, skippable: false },
    last_name: { questionType: 'string', title: 'Επώνυμο', description: 'Επώνυμο', position: 4, skippable: false },
    receipt: { questionType: 'file', title: 'Απόδειξη Ταμειακής Μηχανής', description: 'Receipt', position: 5, image_page:fa, skippable: false },
    edx_certificate: { questionType: 'file', title: 'Ειδική Άδεια Οδήγησης ΕΔΧ', description: 'EDX Certificate', position: 6, image_page:dl, skippable: false },
    vehicle_registration: { questionType: 'file', title: 'Άδεια Κυκλοφορίας', description: 'Vehicle Registration', position: 7, image_page:cs, skippable: false },
    id_front: { questionType: 'file', title: 'Μπροστινή Όψη Αστυνομικής Ταυτότητας / Διαβατηρίου', description: 'ID Front', position: 8, image_page:id, skippable: false },
    id_back: { questionType: 'file', title: 'Πίσω Όψη Αστυνομικής Ταυτότητας / Διαβατηρίου', description: 'ID Back', position: 9, image_page:id_back, skippable: false },
    driver_license_front: { questionType: 'file', title: 'Μπροστινή Όψη Διπλώματος Οδήγησης', description: 'Driver License Front', position: 10, image_page:dlc, skippable: false },
    driver_license_back: { questionType: 'file', title: 'Πίσω Όψη Διπλώματος Οδήγησης', description: 'Driver License Back', position: 11, image_page:dlc_back, skippable: false },
    profile_image: { questionType: 'file', title: 'Εικόνα Προφίλ', description: 'Profile Image', position: 12, image_page:pi, skippable: false },
    vehicle_image: { questionType: 'file', title: 'Φωτογραφία Οχήματος', description: '', position: 13, image_page:ti, skippable: false },
    bank_receipt: { questionType: 'file', title: 'Βεβαίωση Τήρησης Τραπεζικού Λογαριασμού', description: 'Bank Receipt', position: 14, image_page:bs2, skippable: true },
    personal_info_consent: { questionType: 'boolean', title: 'Άδεια Χρήσης Προσωπικών Δεδομένων', description: 'Με την εγγραφή σας, συμφωνείτε με τους Όρους Χρήσης και την Πολιτική Απορρήτου μας, δεσμεύεστε να συμμορφώνεστε με τις υποχρεώσεις σύμφωνα με την Ευρωπαϊκή Ένωση και την τοπική νομοθεσία και να παρέχετε μόνο νόμιμες υπηρεσίες και περιεχόμενο στην πλατφόρμα taxi.gr', position: 15, skippable: false },
};