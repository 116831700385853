import React, { useEffect, useState, useRef } from "react";
import DefaultLanguageInterface from "../../../interfaces";
import { ACTIVE_URL } from "../../../constants";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import HomeNewsItem from "./HomeNewsItem/HomeNewsItem";
import { IonIcon } from "@ionic/react";

type NewsItem = {
    grTitle: string;
    enTitle: string;
    grDescription: string;
    enDescription: string;
    image: string;
    slug: string;
    date: string;
};

const HomeNews: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {
    const [newsItems, setNewsItems] = useState<NewsItem[]>([{} as NewsItem, {} as NewsItem, {} as NewsItem]);
    const fetched = useRef<boolean>(false);
    const [loadingItems, setLoadingItems] = useState<boolean>(true);
    const [hoverTitle, setHoverTitle] = useState<boolean>(false);
    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_recent_news/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setNewsItems([])
                    let temp_arr: any = [];
                    data.map((item: any) => {
                        if (item.slug in temp_arr) return;
                        setNewsItems((prev) => [...prev, {
                            grTitle: item.gr_title,
                            enTitle: item.en_title,
                            grDescription: item.gr_description,
                            enDescription: item.en_description,
                            image: item.image_data,
                            date: item.date,
                            slug: item.slug
                        }]);
                        temp_arr.push(item.slug);
                    })
                    setLoadingItems(false);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }
        // Cleanup function
        if (!fetched.current) {
            fetchResponses();
            fetched.current = true;
        }
    }, []);

    useEffect(() => {
        const tempArr: any = [];
        newsItems.forEach((item, index) => {
            if (index === newsItems.length - 1) {
                if (tempArr.includes(item.slug)) {
                    setNewsItems(prev => prev.slice(0, -1));
                } else {
                    tempArr.push(item.slug);
                }
            }
        });
    }, [newsItems]);

    return (
        <div className="pt-14 pb-14 px-3 xmd:px-0 max-w-1128 m-auto">
            <div className="flex justify-between w-full items-center select-none">
                <h1 className='sm:text-center text-3xl sm:text-4xl xmd:5xl font-semibold mt-10 mb-8 sm:mb-12 text-neutral-800'>{languageData.home.home_news}</h1>
                <div>
                    <a href="/news" className="duration-100 hidden sm:flex text-neutral-700 hover:text-main transition  text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                        <div className="duration-100">{languageData.home.home_news_view_all}</div>
                        <IonIcon icon='arrow-forward-outline' className={`text-xl duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                    </a>
                </div>
            </div>
            <div className="flex flex-col sm:grid  sm:grid-cols-2 xmd:grid-cols-3  gap-4 sm:gap-2 xmd:gap-6">
                {newsItems.map((item, index) => (
                    <div key={index}>
                        <HomeNewsItem loading={loadingItems} grTitle={item.grTitle} enTitle={item.enTitle} grDescription={item.grDescription} enDescription={item.enDescription} image={item.image} slug={item.slug} date={item.date} activeLanguage={activeLanguage} />
                    </div>
                ))}
            </div>
            <div>
                <a href="/news" className="duration-100 flex sm:hidden mt-8 text-neutral-700 hover:text-main transition  text-lg xmd:text-lg font-semibold items-center gap-2" onMouseOver={() => setHoverTitle(true)} onMouseLeave={() => setHoverTitle(false)}>
                    <div className="duration-100">{languageData.home.home_news_view_all}</div>
                    <IonIcon icon='arrow-forward-outline' className={`text-xl duration-200 inline-block ${hoverTitle ? 'translate-x-0.5' : ''}`} />
                </a>
            </div>
        </div>
    );
}

export default HomeNews;