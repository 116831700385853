import React, { useState, useEffect } from 'react';
import { ACTIVE_URL } from '../../../../constants';
import { useAuthContext } from '../../../../utils/useAuthContext';
import AdminBox from '../../Helpers/AdminBox/AdminBox';
import AdimBoxTitle from '../../Helpers/AdminBoxTitle/AdimBoxTitle';
import PrivilageCategory from './PrivilageCategory/PrivilageCategory';


interface Group {
    options: { [key: string]: boolean };
    title: string;
    members: number;
    id: number;
}

const AdminUserGroups = () => {
    const [groups, setGroups] = useState<Group[]>([]);
    const { authTokens } = useAuthContext();

    useEffect(() => {
        const fetchGroups = async () => {
            if (!authTokens || !authTokens.access) {
                return;
            }
            try {
                const response = await fetch(`${ACTIVE_URL}/api/get_groups/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setGroups(data);
                } else {
                    console.error('Failed to fetch responses');
                }
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        }

        fetchGroups();
    }, []);

    const handlePostOptionValue = async (groupId: number, privilageName: string, privilageValue: boolean) => {
        if (!authTokens || !authTokens.access) {
            return;
        }
        try {
            const response = await fetch(`${ACTIVE_URL}/api/post_group_privilage/`, {
                method: 'POST', // Change the method to POST
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`,
                },
                body: JSON.stringify({
                    group_id: groupId,
                    privilage_name: privilageName,
                    privilage_value: privilageValue,
                }),
            });
            if (response.ok) {
                const data = await response.json();
                // Process the response data here
            } else {
                console.error('Failed to fetch responses');
            }
        } catch (error) {
            console.error('Error fetching responses:', error);
        }
    };


    const createOnChangeHandler = (optionName: string, isChecked: boolean, id: number) => {
        const newGroups = [...groups];
        const groupIndex = newGroups.findIndex((group) => group.id === id);
        newGroups[groupIndex].options[optionName] = isChecked;
        setGroups(newGroups);
        handlePostOptionValue(id, optionName, isChecked);
    };

    return (
        <div>
            <AdminBox>
                <AdimBoxTitle title='User Groups' />
                {groups.map((group, index) => (
                    <PrivilageCategory
                        id={group.id}
                        key={index}
                        category_description={group.title}
                        category_name={group.title}
                        options={group.options}
                        members={group.members}
                        onChange={(optionName: string, isChecked: boolean, id: number) => {
                            createOnChangeHandler(optionName, isChecked, id);
                        }}
                    />
                ))}
            </AdminBox>
        </div>
    )
}

export default AdminUserGroups;