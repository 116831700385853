import React from 'react';
import DefaultLanguageInterface from '../../../interfaces';
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import DriversFormNewPage from '../../../images/downloadTheApp/taxi_driv_2_cut.png';

const DriverHowItWorks: React.FC<DefaultLanguageInterface> = ({ activeLanguage }) => {

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <div className='w-full px-4 pb-14'>
            <div className='max-w-1128 m-auto flex flex-col xmd:flex-row w-full items-center justify-center '>
                <div className='flex flex-col justify-end items-center  w-[90%] xmd:w-[80%] pb-8 xmd:pb-0 h-full'>
                    <img src={DriversFormNewPage} alt="taxi.gr driver app screenshot" className='max-h-[470px] mt-10' />
                </div>
                <div className='flex flex-col gap-4'>
                    <h3 className='text-3xl font-bold  text-neutral-800'>
                        {languageData.driver.driver_how_it_works_main_title}
                    </h3>
                    <h5 className='text-lg text-neutral-600 font-extralight'>
                        {languageData.driver.driver_how_it_works_main_subtitle}
                    </h5>
                    <div className='my-2'>
                        <div className='flex gap-4 items-center'>
                            <div className='ion-icon-default-transform bg-main text-xs h-5 w-5 mb-1  items-center flex justify-center rounded-full text-white'>
                                1
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.driver.driver_how_it_works_1_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.driver.driver_how_it_works_1_subtitle}
                        </div>
                    </div>
                    <div className='my-2'>
                        <div className='flex gap-4 items-center'>
                            <div className='ion-icon-default-transform bg-main text-xs h-5 w-5 mb-1 items-center flex justify-center rounded-full text-white'>
                                2
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.driver.driver_how_it_works_2_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.driver.driver_how_it_works_2_subtitle}
                        </div>
                    </div>
                    <div className='my-2'>
                        <div className='flex gap-4 items-center'>
                            <div className='ion-icon-default-transform bg-main text-xs h-5 w-5 mb-1 items-center flex justify-center rounded-full text-white'>
                                3
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.driver.driver_how_it_works_3_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.driver.driver_how_it_works_3_subtitle}
                        </div>
                    </div>
                    <div className='my-2'>
                        <div className='flex gap-4 items-center'>
                            <div className='ion-icon-default-transform bg-main text-xs h-5 w-5 mb-1 items-center flex justify-center rounded-full text-white'>
                                4
                            </div>
                            <h4 className='text-neutral-900 text-lg pb-1'>
                                {languageData.driver.driver_how_it_works_4_title}
                            </h4>
                        </div>
                        <div className='max-w-20 text-sm pl-10 text-neutral-600'>
                            {languageData.driver.driver_how_it_works_4_subtitle}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverHowItWorks;