import React, { useEffect, useRef, useState } from 'react';
import { IonIcon, IonItem, IonLabel, IonSpinner } from '@ionic/react';
import DriverFormFile from './DriverFormFile/DriverFormFile';
import DriverFormEmail from './DriverFormEmail/DriverFormEmail';
import DriverFormProgressBar from './DriverFormProgressBar/DriverFormProgressBar';
import DriverFormPhone from './DriverFormPhone/DriverFormPhone';
import DriverFormCitySelect from './DriverFormSelect/DriverFormSelect';
import { ACTIVE_URL } from '../../constants';
import DriverFormSwitch from './DriverFormSwitch/DriverFormSwitch';
import { Link } from 'react-router-dom';
import { DriverInfo, DriverInfoMetadata, driverInfoMetadataItems } from './DriverFormMetadata';

interface DriverFormNewProps {
    activeLanguage: string;
    email: string;
    phone_number: string;
    city: string;
}

const DriverFormNew: React.FC<DriverFormNewProps> = ({ activeLanguage, email, phone_number, city }) => {
    const [advanceAttempt, setAdvanceAttempt] = useState(false);
    const [isTheLastQuestion, setIsTheLastQuestion] = useState(false);
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const formSubmitted = useRef(false);


    const [driverInfo, setDriverInfo] = useState<DriverInfo>({

        email: email,
        phone_number: phone_number,
        city: city,
        personal_info_consent: true,
        first_name: '',
        last_name: '',

        receipt: null,
        edx_certificate: null,
        vehicle_registration: null,
        id_front: null,
        id_back: null,
        driver_license_front: null,
        driver_license_back: null,
        profile_image: null,
        vehicle_image: null,
        bank_receipt: null,
    });

    const [currentQuestionKey, setCurrentQuestionKey] = useState<keyof DriverInfo | null>(null);
    const [questionHistory, setQuestionHistory] = useState<(keyof DriverInfo)[]>([]);
    const [currentPosition, setCurrentPosition] = useState<number>(-1);



    // For Progress Bar
    const getCurrentQuestionStatus = () => {
        const totalQuestions = Object.keys(driverInfoMetadataItems).length;
        const latestPosition = currentQuestionKey ? driverInfoMetadataItems[currentQuestionKey].position + 1 : 1; // Assuming position starts at 0
        return { latestPosition, totalQuestions };
    };
    const { latestPosition, totalQuestions } = getCurrentQuestionStatus();
    useEffect(() => {
        setIsTheLastQuestion(currentPosition === totalQuestions - 1)
    }, [currentQuestionKey]);
    // Store the driver info in local storage
    const handleInputChange = (key: keyof DriverInfo, value: any) => {
        setDriverInfo(prev => ({ ...prev, [key]: value }));
    };

    // Check if the next button should be disabled
    const isNextButtonDisabled = () => {

        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'file' && !driverInfo[currentQuestionKey]) {
            return true;
        }
        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'email' && driverInfo[currentQuestionKey] === '') {
            return true;
        }
        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'phone' && driverInfo[currentQuestionKey] === '') {
            return true;
        }
        if (currentQuestionKey) {
            const value = driverInfo[currentQuestionKey];
            if (typeof value === 'string' && value.length < 3) {
                return true;
            }
        }
        if (currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].questionType === 'boolean' && driverInfo[currentQuestionKey] === false) {
            return true;
        }
        return false;
    };

    // Determine the current question based on currentPosition and questionHistory
    useEffect(() => {
        // Determine the first question on initial load
        if (currentPosition === -1 && questionHistory.length === 0) {
            const firstQuestionKey = getNextQuestion();
            if (firstQuestionKey) {
                setCurrentQuestionKey(firstQuestionKey);
                setQuestionHistory([firstQuestionKey]);
                setCurrentPosition(0);
            }
        }
        // Adjust the current question based on currentPosition whenever it changes
        else if (currentPosition >= 0 && currentPosition < questionHistory.length) {
            setCurrentQuestionKey(questionHistory[currentPosition]);
        }
    }, [currentPosition, questionHistory]);

    const getNextQuestion = (): keyof DriverInfo | null => {
        // Ensure all keys in questionHistory are valid and exclude null explicitly.
        const validAnsweredQuestionsKeys = questionHistory.filter((key): key is keyof DriverInfo => key !== null);

        // Calculate the currentPosition based on positions of answered questions.
        const currentPosition = validAnsweredQuestionsKeys.length > 0
            ? Math.max(...validAnsweredQuestionsKeys.map(key => driverInfoMetadataItems[key].position), -1)
            : -1;

        // Find the next question based on the currentPosition.
        const nextQuestionEntry = Object.entries(driverInfoMetadataItems)
            .filter(([key, _]) => !validAnsweredQuestionsKeys.includes(key as keyof DriverInfo))
            .sort((a, b) => a[1].position - b[1].position)
            .find(([_, metadata]) => metadata.position > currentPosition);

        return nextQuestionEntry ? nextQuestionEntry[0] as keyof DriverInfo : null;
    };

    const updateDriverInfoAndAdvance = (key: keyof DriverInfo, newValue: any) => {
        setDriverInfo(prev => ({ ...prev, [key]: newValue }));

        if (!questionHistory.includes(key)) {
            setQuestionHistory(prev => [...prev, key]);
        }
        // Advance to the next question
        setAdvanceAttempt(true);
    };

    const skipQuestion = () => {
        const nextQuestion = getNextQuestion();
        if (nextQuestion) {
            setQuestionHistory(prev => {
                const newHistory = [...prev, nextQuestion];
                return newHistory;
            });
            setAdvanceAttempt(true)
        } else {
            console.log("No more questions to skip to.");
        }
    };
    console.log(questionHistory.length)
    useEffect(() => {
        if (advanceAttempt || questionHistory.length === 0) {
            const nextQuestion = getNextQuestion();
            setAdvanceAttempt(false);
            if (nextQuestion) {
                setCurrentQuestionKey(nextQuestion);
                setCurrentPosition(driverInfoMetadataItems[nextQuestion].position);
            } else {
                console.log("All questions have been addressed.");
            }
        }
    }, [advanceAttempt, questionHistory.length]);

    const handleGoBack = () => {
        if (currentPosition > 0) {
            setQuestionHistory(prevHistory => {
                const newHistory = [...prevHistory];
                newHistory.pop(); // Remove the current question from the history
    
                // Update the current position to the previous one
                const newPosition = newHistory.length - 1;
                setCurrentPosition(newPosition);
    
                // Fetch the previous question's key from the updated history
                const previousQuestionKey = newHistory[newPosition];
                if (previousQuestionKey) {
                    setCurrentQuestionKey(previousQuestionKey);
                }
    
                return newHistory; // Ensure to return the updated history
            });
        }
    };
    const renderCurrentQuestion = () => {
        if (!currentQuestionKey) return <div>All questions answered or no questions found.</div>;
        const metadata = driverInfoMetadataItems[currentQuestionKey];
        const value = driverInfo[currentQuestionKey];

        switch (metadata.questionType) {
            case 'string':
                return (
                    <div className='flex flex-col gap-2'>
                        <div className='text-white text-lg'>{metadata.title}</div>
                        <input
                            type="text"
                            className={`w-full text-large py-2 pl-2 pr-2 text-neutral-900 rounded-md outline-none bg-neutral-100 border  focus:shadow-md`}
                            placeholder={metadata.description} value={value as string || ''}
                            onChange={(e) => handleInputChange(currentQuestionKey, e.target.value)}
                        />
                    </div>
                );
            case 'phone':
                return (
                    <div className='w-full flex flex-col gap-1'>
                        <div className='text-white text-lg'>{metadata.title}</div>
                        <DriverFormPhone
                            sendPhone={(phone) => handleInputChange(currentQuestionKey, phone)}
                            preSelectedPhone={driverInfo[currentQuestionKey] as string}
                        />
                    </div>
                )
            case 'city-select':
                return (
                    <div className='flex flex-col gap-2'>
                        <div className='text-white text-lg'>{metadata.title}</div>
                        <DriverFormCitySelect
                            selectedCity={value as string}
                            setSelectedCity={(city: string) => handleInputChange(currentQuestionKey, city)}
                        />
                    </div>
                )
            case 'email':
                return (
                    <div className='w-full flex flex-col gap-1'>
                        <div className='text-white text-lg'>{metadata.title}</div>
                        <DriverFormEmail
                            sendEmail={(email) => handleInputChange(currentQuestionKey, email)}
                            preSelectedEmail={driverInfo[currentQuestionKey] as string}
                        />
                    </div>
                )
            case 'file':
                return (
                    <div className='flex flex-col gap-2 h-full'>
                        <div className='flex items-center w-full justify-start'>
                            <img src={metadata.image_page} alt={metadata.title} className='w-auto max-h-[40vh] rounded-sm' />
                        </div>

                        <div className='text-white text-lg'>{metadata.title}</div>
                        <DriverFormFile
                            key={currentQuestionKey}
                            sendFile={(file) => handleInputChange(currentQuestionKey, file)}
                            preSelectedFile={driverInfo[currentQuestionKey] as File | null}
                        />
                    </div>
                );
            case 'boolean':
                return (
                    <div className='flex flex-col gap-4 mb-2'>
                        <div className='text-white text-lg'>{metadata.title}</div>
                        <div className='text-sm text-neutral-500 flex flex-row items-center gap-4'>
                            <DriverFormSwitch value={driverInfo.personal_info_consent} setSelectedValue={(value) => handleInputChange(currentQuestionKey, value)} />
                            <div className='text-neutral-100'>
                                {metadata.description}
                            </div>
                        </div>

                        {/* <input type="checkbox" checked={!!value} onChange={(e) => updateDriverInfoAndAdvance(currentQuestionKey, e.target.checked)} /> */}
                    </div>
                );
            default:
                return null;
        }
    };

    const submitDriverForm = async () => {
        if (formSubmitted.current) {
            return;
        }
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('first_name', driverInfo.first_name);
            formData.append('last_name', driverInfo.last_name);
            formData.append('email', driverInfo.email);
            formData.append('phone_number', driverInfo.phone_number);
            formData.append('city', driverInfo.city);
            formData.append('personal_info_consent', driverInfo.personal_info_consent ? 'true' : 'false');
            formData.append('receipt', driverInfo.receipt as File);
            formData.append('edx_certificate', driverInfo.edx_certificate as File);
            formData.append('vehicle_registration', driverInfo.vehicle_registration as File);
            formData.append('id_front', driverInfo.id_front as File);
            formData.append('id_back', driverInfo.id_back as File);
            formData.append('driver_license_front', driverInfo.driver_license_front as File);
            formData.append('driver_license_back', driverInfo.driver_license_back as File);
            formData.append('profile_image', driverInfo.profile_image as File);
            formData.append('vehicle_image', driverInfo.vehicle_image as File);
            formData.append('bank_receipt', driverInfo.bank_receipt as File);


            const response = await fetch(`${ACTIVE_URL}/api/post_driver_form/`, {
                method: 'POST',
                body: formData,
            });
            formSubmitted.current = true;
            if (response.ok) {
                const data = await response.json();
                setCompleted(true);
                setLoading(false);
            } else {
                console.error('Failed to post');
                console.log(response)
            }
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error fetching responses:', error);
            } else {
                console.error('An unknown error occurred:', error);
            }
        }
    }

    return (

        <div className='min-h-screen pt-16 pb-8'>
            <div className='px-2 max-w-1128 m-auto '>
                {
                    !completed ? (

                        <div className='bg-main bg-opacity-95 rounded-md min-h-[90vh] flex items-center justify-center relative'>
                            <DriverFormProgressBar currentStep={latestPosition} totalSteps={totalQuestions} />
                            <form onSubmit={(e) => e.preventDefault()} className='flex flex-col h-full gap-5 sm:gap-4 w-full px-4 md:w-1/2 max-w-[500px]'>

                                {renderCurrentQuestion()}
                                <div className='w-full flex items-center justify-between flex-row-reverse sm:flex-row'>
                                    <div className='flex gap-2'>
                                        <button
                                            className={`${loading ? 'bg-secondary' : 'bg-secondary cursor-pointer'} text-white rounded text-base px-4 py-2 duration-100 shadow-lg flex items-center justify-center gap-2 `}
                                            type="button"
                                            disabled={isNextButtonDisabled() || loading}
                                            onClick={() => {
                                                if (currentQuestionKey) {
                                                    if (isTheLastQuestion) {
                                                        submitDriverForm();
                                                    } else {
                                                        updateDriverInfoAndAdvance(currentQuestionKey, driverInfo[currentQuestionKey]);
                                                    }
                                                }
                                            }}>
                                            {loading ? (
                                                <>
                                                    <div className='px-10 flex gap-4 text-white'>
                                                        <IonSpinner name="crescent" ></IonSpinner>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {isTheLastQuestion ? 'Υποβολή' : 'Επόμενο'}
                                                    <IonIcon icon='chevron-forward' className='mt-[1px]' />
                                                </>
                                            )}
                                        </button>
                                        {currentQuestionKey && driverInfoMetadataItems[currentQuestionKey].skippable && (
                                            <button
                                                className='bg-gray-400 text-neutral-100 rounded text-base px-4 py-2 duration-100 shadow-lg flex items-center justify-center gap-2'
                                                type="button"
                                                onClick={skipQuestion}
                                            >
                                                Παράληψη
                                            </button>
                                        )}
                                    </div>

                                    {currentPosition > 0 && (
                                        <button className={`${loading ? 'bg-neutral-300 text-neutral-700' : 'bg-neutral-100 text-neutral-800 hover:bg-neutral-50 cursor-pointer'}  rounded text-base px-4 py-2  duration-100 flex items-center justify-center gap-2`} disabled={loading} type="button" onClick={handleGoBack}>
                                            <IonIcon icon='chevron-back' className='mt-[1px]' />
                                            <span className=''>Πίσω</span>
                                        </button>
                                    )}

                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className='bg-main bg-opacity-95 text-white rounded-md min-h-[90vh] flex items-center gap-6  flex-col justify-center relative px-4 md:px-0'>
                            <h3 className='text-4xl '>
                                Καλωσήρθατε!
                            </h3>
                            <p className='max-w-[500px] text-center'>
                                Αγαπητέ/ή {driverInfo.first_name} {driverInfo.last_name}, η αίτησή σας ολοκληρώθηκε με επιτυχία!
                                Σας έχει αποσταλεί email επιβεβαίωσης στο {driverInfo.email}.
                            </p>
                            <Link to={`${activeLanguage == 'en' ? '/en' : ''}/`} className='bg-white px-6 py-2 text-main rounded-md'>
                                Πίσω στην αρχική
                            </Link>
                        </div>
                    )}

            </div>

        </div>
    );
};

export default DriverFormNew;