import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from 'react-cookie';
import QuestionaireIntro from '../QuestionaireIntro/QuestionaireIntro';
import Question from '../Question/Question';
import QuestionaireEnd from '../QuestionaireOutro/QuestionaireOutro';
import './QuestionaireWrapper.css';
import { ReactComponent as LogoSvg } from '../../../images/logo.svg';

const QuestionaireWrapper = () => {
  const [questionaire, setQuestionaire] = useState(1);
  const [questionaireState, setQuestionaireState] = useState('intro');
  const [continueState, setContinueState] = useState('start');
  const [response_id, setResponseId] = useState('');
  const [cookies, setCookie] = useCookies(['response_id']);

  // On first render, check if the response_id cookie exists - Used for saving responses even if the user closes the browser
  useEffect(() => {
    // Check if the response_id cookie exists
    if (cookies.response_id && continueState == 'start') {
      // If the cookie exists, set response_id to its value
      setResponseId(cookies.response_id);
      setContinueState('continue');
    } else {
      // If the cookie doesn't exist, initialize response_id with a new UUID
      const newResponseId = uuidv4();
      // 1/5.3 × 10^36 chance of collision
      setResponseId(newResponseId);

      // Set the response_id cookie with an expiration date of 7 days
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 7);

      // Set the response_id cookie with the new UUID
      setCookie('response_id', newResponseId, { path: '/' });
    }
  }, []);

  const changeQuestionState = (newMode: string) => {
    setQuestionaireState(newMode);
  };


  return (
    <div className='min-h-screen w-full bg-main flex items-center justify-center text-white'>
      <div className='absolute top-8 left-0 w-full flex flex-col gap-2 items-center justify-center'>
        <LogoSvg fill={`white`} className="h-[1.95rem]" />
        <h2 className='text-xl'>
            Εγγραφή οδηγού
        </h2>
      </div>
      <div className='questionaire-wrapper-background'></div>

      <div className='max-w-[500px]'>
        {questionaireState === 'intro' ? (<QuestionaireIntro questionaireId={questionaire} continueS={continueState} onButtonClick={changeQuestionState} />) : null}
        {questionaireState === 'question' ? (<Question questionaireId={questionaire} responseId={response_id} onEnd={changeQuestionState} />) : null}
        {questionaireState === 'end' ? (<QuestionaireEnd />) : null}
      </div>
    </div>
  );
};

export default QuestionaireWrapper;