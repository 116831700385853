import React from "react";
import DefaultLanguageInterface from "../../../interfaces";
import greekLanguage from '../../../locales/gr.json';
import englishLanguage from '../../../locales/en.json';
import WhyUs from "../../Home/WhyUs/WhyUs";


const DriverReasons:React.FC<DefaultLanguageInterface> = ({activeLanguage}) => {

    const languageData = activeLanguage === 'el' ? greekLanguage : englishLanguage;

    return (
        <div className="max-w-1128 select-none m-auto w-full mt-16 mb-20 px-4 xmd:px-2">
            <h2 className="text-4xl xmd:text-5xl mb-4 xmd:mb-16  text-neutral-800 w-full xmd:w-[650px] xmd:max-w-[50vw] font-semibold ">{languageData.driver.reasons_title}</h2>
            <WhyUs activeLanguage={activeLanguage}  />
        </div>
    )
}

export default DriverReasons;